@charset "UTF-8";
/*!
 * Copyright (©) 11ST Corp. All Rights Reserved.
 */
/* noto-sans-kr-300 - latin_korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("//c.011st.com/css/fonts/noto-sans-kr-v13-latin_korean-300.woff2") format("woff2"), url("//c.011st.com/css/fonts/noto-sans-kr-v13-latin_korean-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-regular - latin_korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("//c.011st.com/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff2") format("woff2"), url("//c.011st.com/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-500 - latin_korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: local("Noto Sans KR Medium"), local("NotoSansKR-Medium"), url("//c.011st.com/css/fonts/noto-sans-kr-v12-latin_korean-500.woff2") format("woff2"), url("//c.011st.com/css/fonts/noto-sans-kr-v12-latin_korean-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-700 - latin_korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"), url("//c.011st.com/css/fonts/noto-sans-kr-v12-latin_korean-700.woff2") format("woff2"), url("//c.011st.com/css/fonts/noto-sans-kr-v12-latin_korean-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-regular - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url("//c.011st.com/css/fonts/lato-v16-latin-regular.woff2") format("woff2"), url("//c.011st.com/css/fonts/lato-v16-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0030-0039;
}

/* lato-900 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: local("Lato Black"), local("Lato-Black"), url("//c.011st.com/css/fonts/lato-v16-latin-900.woff2") format("woff2"), url("//c.011st.com/css/fonts/lato-v16-latin-900.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0030-0039;
}

@font-face {
  font-family: "11StreetGothic";
  font-style: normal;
  font-weight: 400;
  src: url("//c.011st.com/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff2"), url("//c.011st.com/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff");
}

@font-face {
  font-family: "11StreetGothic";
  src: url("//c.011st.com/css/fonts/11StreetGothic_Optimizing.woff") format("woff2"), url("//c.011st.com/css/fonts/11StreetGothic_Optimizing.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "11StreetGothicBold";
  font-weight: bold;
  src: url("//c.011st.com/css/fonts/11STREET_Gothic_bold.eot");
  src: url("//c.011st.com/css/fonts/11STREET_Gothic_bold.woff") format("woff2"), url("//c.011st.com/css/fonts/11STREET_Gothic_bold.woff") format("woff"), url("//c.011st.com/css/fonts/11STREET_Gothic_bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("//c.011st.com/css/fonts/RobotoCondensed-Bold.eot");
  src: url("//c.011st.com/css/fonts/RobotoCondensed-Bold.woff") format("woff2"), url("//c.011st.com/css/fonts/RobotoCondensed-Bold.woff") format("woff"), url("//c.011st.com/css/fonts/RobotoCondensed-Bold.ttf") format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: bold;
}

h1 {
  font-size: 1.8125rem;
}

h2 {
  font-size: 1.5625rem;
}

h3 {
  font-size: 1.4375rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

html {
  line-height: 1.15;
  height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background: #fff;
  color: #666;
  font-family: -apple-system, BlinkMacSystemFont, "Apple SD Gothic", "Malgun Gothic", "돋움", "Dotum", arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  margin: 0;
  overflow-wrap: break-word;
  -webkit-text-size-adjust: none;
}

body * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body *:before,
body *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
select,
textarea,
p,
blockquote,
th,
td,
button {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Apple SD Gothic", "Malgun Gothic", "돋움", "Dotum", arial, sans-serif;
}

section,
nav,
article,
aside,
hgroup,
header,
footer {
  display: block;
}

form,
fieldset,
img {
  border: 0 none;
}

table {
  border-spacing: 0;
  border: 0;
}

em {
  font-style: normal;
}

ul,
ol,
li {
  list-style: none outside;
}

input,
select {
  vertical-align: middle;
}

a {
  text-decoration: none;
  color: inherit;
}

legend,
caption,
.hide {
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
}

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="search"],
input[type="email"] {
  background: #fff;
  color: #666;
  border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

button,
input[type="submit"],
input[type="button"] {
  cursor: pointer;
  border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
}

select {
  border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

textarea {
  border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

iframe {
  display: block;
}

.sr-only,
.skip {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  width: 1px;
  height: 1px;
  font-size: 6px;
  line-height: 1;
  white-space: nowrap;
}

.js-focus-visible button:focus:not(.focus-visible),
.js-focus-visible input[type="checkbox"]:focus:not(.focus-visible),
.js-focus-visible input[type="radio"]:focus:not(.focus-visible),
.js-focus-visible select:focus:not(.focus-visible),
.js-focus-visible summary:focus:not(.focus-visible) {
  outline: 0;
}

.js-focus-visible select:focus:not(.focus-visible)::-ms-value {
  background-color: transparent;
  color: inherit;
}

.js-focus-visible input[type="checkbox"]:focus:not(.focus-visible) + label::before,
.js-focus-visible input[type="radio"]:focus:not(.focus-visible) + label::before {
  box-shadow: none !important;
}

.js-focus-visible input[type="checkbox"]:focus:not(.focus-visible) + label,
.js-focus-visible input[type="radio"]:focus:not(.focus-visible) + label {
  box-shadow: none !important;
}

.js-focus-visible [role="listbox"]:focus:not(.focus-visible) {
  outline: 0;
}

.c-button {
  display: inline-block;
  padding: 0 8px;
  border-radius: 0;
  text-align: center;
}

.c-button--primary {
  border: 1px solid #ff0038;
  background: #ff0038;
  color: #fff;
}

.c-button--alert {
  border: 1px solid #ff0038;
  background: #fff;
  color: #ff0038;
}

.c-button--normal {
  border: 1px solid #ccc;
  background: #fff;
  color: #333;
}

.c-button:disabled, .c-button--disabled {
  border: 1px solid #ccc;
  background: #ccc;
  color: #fff;
}

.c-button--dimmed {
  border: 1px solid rgba(204, 204, 204, 0.2);
  background: #fafafa;
  color: #ccc;
}

.c-button__point {
  color: #ff0038;
}

.c-radio,
.c-checkbox {
  height: 22px;
  width: 22px;
  position: absolute;
  opacity: 0;
}

.c-radio + label,
.c-checkbox + label {
  display: block;
  position: relative;
  min-height: 22px;
  padding-left: 30px;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
}

.c-radio + label:before,
.c-checkbox + label:before {
  height: 22px;
  width: 22px;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #bbb;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
  content: "";
}

.c-radio + label:after,
.c-checkbox + label:after {
  position: absolute;
  content: "";
}

.c-radio[disabled],
.c-checkbox[disabled] {
  cursor: not-allowed;
}

.c-radio[disabled] + label,
.c-checkbox[disabled] + label {
  cursor: not-allowed;
  color: #ccc;
}

.c-radio[disabled] + label:before,
.c-checkbox[disabled] + label:before {
  border-color: #eee;
  background: #eee;
}

.c-radio[disabled] + label:hover, .c-radio[disabled] + label:before, .c-radio[disabled] + label:after,
.c-checkbox[disabled] + label:hover,
.c-checkbox[disabled] + label:before,
.c-checkbox[disabled] + label:after {
  cursor: not-allowed;
}

.c-radio:focus + label:before,
.c-checkbox:focus + label:before {
  box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
}

.c-radio:checked + label:before,
.c-checkbox:checked + label:before {
  border-color: #ff0038;
  background: #ff0038;
  animation-name: none;
}

.c-radio:checked + label:after,
.c-checkbox:checked + label:after {
  display: block;
}

.c-radio:checked[disabled] + label:before,
.c-checkbox:checked[disabled] + label:before {
  border-color: #eee;
  background: #eee;
}

.c-checkbox + label {
  color: #333;
}

.c-checkbox + label:after {
  height: 10px;
  width: 6px;
  top: 4px;
  left: 8px;
  border: 1px solid #ddd;
  border-width: 0 1px 1px 0;
  box-sizing: border-box;
  transform: rotate(45deg);
}

.c-checkbox:checked + label:after {
  border-color: #fff;
}

.c-checkbox[disabled] + label:after {
  border-color: #fff;
}

.c-checkbox--shape + label:before {
  border-radius: 0;
}

.c-radio + label:before {
  border-radius: 50%;
}

.c-radio + label:after {
  height: 10px;
  width: 10px;
  background: #ddd;
  top: 6px;
  left: 6px;
  border-radius: 50%;
}

.c-radio[disabled] + label:after {
  background: #fff;
}

.c-radio:checked + label:after {
  background-color: #fff;
}

.c-spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  color: transparent;
  text-indent: -9999px;
}

.c-spinner:before {
  height: 60px;
  width: 60px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 200;
  margin: -30px 0 0 -30px;
  border-radius: 30px;
  background: #fff url("/assets/img/common/loading2.gif") no-repeat 50% 50%;
  background-size: 30px 30px;
  color: transparent;
  content: "";
}

.c-spinner > span {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  margin: -1px;
  width: 1px;
  height: 1px;
  font-size: 6px;
  line-height: 1;
  color: transparent;
  white-space: nowrap;
  user-select: none;
}

.c-spinner-inner {
  padding: 50px 0;
  text-align: center;
}

.c-spinner-inner:before {
  height: 60px;
  width: 60px;
  display: inline-block;
  border-radius: 30px;
  background: #fff url("/assets/img/common/loading.gif") no-repeat 50% 50%;
  background-size: 40px 40px;
  color: transparent;
  content: "";
}

.c-spinner-inner > span {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  margin: -1px;
  width: 1px;
  height: 1px;
  font-size: 6px;
  line-height: 1;
  color: transparent;
  white-space: nowrap;
  user-select: none;
}

.b-layer__modal {
  display: none;
  overflow: hidden;
  position: fixed;
  width: 100%;
  max-width: 1280px;
  padding-top: 56px;
  background-color: #fff;
  z-index: 103;
  box-sizing: border-box;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 -2px 5px 2px rgba(0, 0, 0, 0.15);
  outline: none;
}

.b-layer__modal-title {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 56px;
  padding: 0 16px;
  color: #000;
  font-size: 19px;
  line-height: 58px;
  text-align: center;
  box-sizing: border-box;
}

.b-layer__modal-title.b-layer__modal-left {
  text-align: left;
}

.b-layer__modal-content {
  overflow: hidden;
  max-height: calc(100vh - 257px);
  padding: 16px;
}

@media screen and (orientation: landscape) {
  .b-layer__modal-content {
    max-height: 100%;
  }
}

.b-layer__modal-close {
  position: absolute;
  top: 8px;
  right: 9px;
  width: 44px;
  height: 44px;
  background: none;
  border: none;
  color: transparent;
}

.b-layer__modal-close:before, .b-layer__modal-close:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 50px;
  margin: -27px 0 0 -1px;
  background: #333;
}

.b-layer__modal-close:before {
  transform: scale(0.5) rotate(45deg);
}

.b-layer__modal-close:after {
  transform: scale(0.5) rotate(-45deg);
}

@media screen and (min-width: 768px) {
  .join-wrapper .b-layer__modal {
    max-width: 400px;
  }
}

.join-wrapper .b-layer__modal-content {
  overflow-y: auto;
  padding: 0;
  max-height: calc(100vh - 125px);
}

.join-wrapper .b-layer__modal .c-select__button {
  width: 100%;
  padding: 13px 16px;
  font-size: 16px;
  color: #333;
  line-height: 22px;
  text-align: left;
}

.join-wrapper .b-layer__modal .c-select__button[aria-selected="true"] {
  background-color: #fafafa;
  font-weight: 600;
}

.join-wrapper .b-layer__modal .c-select__button:hover, .join-wrapper .b-layer__modal .c-select__button:focus {
  background-color: #fafafa;
}

.join-wrapper .b-layer__modal .c-terms__box {
  display: flex;
  align-items: center;
  height: 56px;
  margin: 10px 20px 0;
  border-radius: 4px;
  background-color: #fafafa;
  font-weight: 700;
}

.join-wrapper .b-layer__modal .c-terms__box label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 50px;
}

.join-wrapper .b-layer__modal .c-terms__box label:before {
  top: 50%;
  left: 20px;
  margin-top: -11px;
}

.join-wrapper .b-layer__modal .c-terms__box label:after {
  top: 50%;
  left: 28px;
  margin-top: -7px;
}

.join-wrapper .b-layer__modal .c-terms__list {
  padding: 0 20px 24px;
}

.join-wrapper .b-layer__modal .c-terms__item {
  margin-top: 20px;
}

.join-wrapper .b-layer__modal .c-terms__details {
  display: none;
  margin-top: 10px;
  padding: 0 26px;
}

.join-wrapper .b-layer__modal .c-terms__text {
  margin-top: 11px;
  font-size: 13px;
  color: #949494;
}

.join-wrapper .b-layer__modal .c-terms__button .c-button {
  width: 100%;
  height: 52px;
  font-size: 17px;
  font-weight: 700;
  line-height: 48px;
}

.join-wrapper .b-layer__modal .c-agree {
  position: relative;
  display: flex;
  padding-left: 30px;
  line-height: 24px;
}

.join-wrapper .b-layer__modal .c-agree__input {
  position: absolute;
  top: 50%;
  left: 0;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  opacity: 0;
}

.join-wrapper .b-layer__modal .c-agree__input:checked + label:before {
  width: 14px;
  height: 14px;
  background-position: -216px -26px;
  background-size: 234px 198px;
}

.join-wrapper .b-layer__modal .c-agree__label {
  font-size: 15px;
  color: #111;
}

.join-wrapper .b-layer__modal .c-agree__label:before {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 14px;
  height: 14px;
  background-position: -216px -4px;
  background-size: 234px 198px;
  content: "";
  position: absolute;
  top: 50%;
  left: 6px;
  margin-top: -7px;
}

.join-wrapper .b-layer__modal .c-agree__link {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-left: auto;
}

.join-wrapper .b-layer__modal .c-agree__link:after {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 6px;
  height: 11px;
  background-position: -216px -86px;
  background-size: 234px 198px;
  content: "";
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
}

.join-wrapper .b-layer__modal .c-agree__button {
  width: 24px;
  height: 24px;
  margin-left: auto;
}

.join-wrapper .b-layer__modal .c-agree__button:after {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 13px;
  height: 8px;
  background-position: -216px -70px;
  background-size: 234px 198px;
  content: "";
  display: block;
  margin: 0 auto;
  transform: rotate(-180deg);
}

.join-wrapper .b-layer__modal .c-agree--open .c-agree__button:after {
  transform: none;
}

.join-wrapper .b-layer__modal .c-agree--open + .c-terms__details {
  display: block;
}

.join-wrapper .b-layer__modal .c-agree--sub {
  padding-right: 0;
}

.join-wrapper .b-layer__modal .c-agree--sub:not(:first-child) {
  margin-top: 4px;
}

.join-wrapper .b-layer__modal .c-agree--sub .c-agree__label {
  color: #666;
}

.b-layer__modal--responsive {
  padding-top: 0;
}

@media screen and (min-width: 768px) {
  .b-layer__modal--responsive {
    width: 340px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .b-layer__modal--responsive.slideOutBottom {
    animation-name: fadeOut;
  }
  .b-layer__modal--responsive.slideInBottom {
    animation-name: fadeIn;
  }
  .b-layer__modal--responsive.modal-bottom {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.animated {
  animation-duration: .4s;
  animation-fill-mode: backwards;
}

.modal-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-top {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.modal-bottom {
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.modal-left {
  top: 0;
  left: 0;
}

.modal-right {
  top: 0;
  left: 100%;
  transform: translate(-100%, 0);
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideInTop {
  animation-name: slideInTop;
}

@keyframes slideInTop {
  from {
    transform: translate(-50%, -100%);
  }
  to {
    transform: translate(-50%, 0);
  }
}

.slideInBottom {
  animation-name: slideInBottom;
}

@keyframes slideInBottom {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, -100%);
  }
}

.slideOutBottom {
  animation-name: slideOutBottom;
}

@keyframes slideOutBottom {
  from {
    transform: translate(-50%, -100%);
  }
  to {
    transform: translate(-50%, 0);
  }
}

.slideOutTop {
  animation-name: slideOutTop;
}

@keyframes slideOutTop {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, -100%);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.dim,
.backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 102;
  background-color: rgba(0, 0, 0, 0.2);
}

[data-ui-type="Contents_Auth_App"] .c-util, [data-ui-type="Contents_Auth_Mail"] .c-util, [data-ui-type="Contents_Auth_Pin"] .c-util, [data-ui-type="Contents_Auth_Phone"] .c-util {
  display: flex;
  position: relative;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_App"] .c-util, [data-ui-type="Contents_Auth_Mail"] .c-util, [data-ui-type="Contents_Auth_Pin"] .c-util, [data-ui-type="Contents_Auth_Phone"] .c-util {
    margin-bottom: 54px;
  }
}

[data-ui-type="Contents_Auth_App"] .c-util__item, [data-ui-type="Contents_Auth_Mail"] .c-util__item, [data-ui-type="Contents_Auth_Pin"] .c-util__item, [data-ui-type="Contents_Auth_Phone"] .c-util__item {
  flex: 1;
}

[data-ui-type="Contents_Auth_App"] .c-util__item:before, [data-ui-type="Contents_Auth_Mail"] .c-util__item:before, [data-ui-type="Contents_Auth_Pin"] .c-util__item:before, [data-ui-type="Contents_Auth_Phone"] .c-util__item:before, [data-ui-type="Contents_Auth_App"] .c-util__item:after, [data-ui-type="Contents_Auth_Mail"] .c-util__item:after, [data-ui-type="Contents_Auth_Pin"] .c-util__item:after, [data-ui-type="Contents_Auth_Phone"] .c-util__item:after {
  content: " ";
  display: table;
}

[data-ui-type="Contents_Auth_App"] .c-util__item:after, [data-ui-type="Contents_Auth_Mail"] .c-util__item:after, [data-ui-type="Contents_Auth_Pin"] .c-util__item:after, [data-ui-type="Contents_Auth_Phone"] .c-util__item:after {
  clear: both;
}

[data-ui-type="Contents_Auth_App"] .c-util__item .c-checkbox + label, [data-ui-type="Contents_Auth_Mail"] .c-util__item .c-checkbox + label, [data-ui-type="Contents_Auth_Pin"] .c-util__item .c-checkbox + label, [data-ui-type="Contents_Auth_Phone"] .c-util__item .c-checkbox + label {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  color: #000;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_App"] .c-util__item .c-checkbox + label, [data-ui-type="Contents_Auth_Mail"] .c-util__item .c-checkbox + label, [data-ui-type="Contents_Auth_Pin"] .c-util__item .c-checkbox + label, [data-ui-type="Contents_Auth_Phone"] .c-util__item .c-checkbox + label {
    margin-top: 12px;
    margin-left: 1px;
    line-height: 21px;
  }
}

[data-ui-type="Contents_Auth_App"] .c-util__link-arrow, [data-ui-type="Contents_Auth_Mail"] .c-util__link-arrow, [data-ui-type="Contents_Auth_Pin"] .c-util__link-arrow, [data-ui-type="Contents_Auth_Phone"] .c-util__link-arrow {
  float: right;
  margin-top: 12px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  line-height: 17px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_App"] .c-util__link-arrow, [data-ui-type="Contents_Auth_Mail"] .c-util__link-arrow, [data-ui-type="Contents_Auth_Pin"] .c-util__link-arrow, [data-ui-type="Contents_Auth_Phone"] .c-util__link-arrow {
    margin-bottom: 0;
    font-size: 13px;
    color: #111;
    line-height: 22px;
  }
}

[data-ui-type="Contents_Auth_App"] .c-util__link-arrow:after, [data-ui-type="Contents_Auth_Mail"] .c-util__link-arrow:after, [data-ui-type="Contents_Auth_Pin"] .c-util__link-arrow:after, [data-ui-type="Contents_Auth_Phone"] .c-util__link-arrow:after {
  display: inline-block;
  margin: 0 2px;
  vertical-align: 1px;
  content: '';
  width: 8px;
  height: 8px;
  border: 1px solid currentColor;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
}

[data-ui-type="Contents_Auth_App"] .c-tooltip, [data-ui-type="Contents_Auth_Mail"] .c-tooltip, [data-ui-type="Contents_Auth_Pin"] .c-tooltip, [data-ui-type="Contents_Auth_Phone"] .c-tooltip {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 9px;
  border: 1px solid rgba(11, 131, 230, 0.2);
  border-radius: 100px;
  background: rgba(11, 131, 230, 0.02);
  height: 25px;
  margin-top: 5px;
  background-size: auto 100%;
  font-weight: 700;
  font-size: 11px;
  color: #0b83e6;
  vertical-align: top;
  position: absolute;
  width: auto;
  top: 4px;
  left: -9px;
  letter-spacing: 0;
  line-height: inherit;
  box-shadow: none;
}

[data-ui-type="Contents_Auth_App"] .c-tooltip:after, [data-ui-type="Contents_Auth_Mail"] .c-tooltip:after, [data-ui-type="Contents_Auth_Pin"] .c-tooltip:after, [data-ui-type="Contents_Auth_Phone"] .c-tooltip:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 13px;
  width: 12px;
  height: 6px;
  background: url(/assets/img/svg/member/bg_bubble_blue_tail.svg) no-repeat;
  background-size: 100% 100%;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_App"] .c-tooltip, [data-ui-type="Contents_Auth_Mail"] .c-tooltip, [data-ui-type="Contents_Auth_Pin"] .c-tooltip, [data-ui-type="Contents_Auth_Phone"] .c-tooltip {
    height: 28px;
    padding: 0 11px;
    font-size: 12px;
    margin-top: 6px;
  }
  [data-ui-type="Contents_Auth_App"] .c-tooltip:after, [data-ui-type="Contents_Auth_Mail"] .c-tooltip:after, [data-ui-type="Contents_Auth_Pin"] .c-tooltip:after, [data-ui-type="Contents_Auth_Phone"] .c-tooltip:after {
    height: 7px;
  }
}

[data-ui-type="Contents_Auth_App"] .c-tooltip:before, [data-ui-type="Contents_Auth_Mail"] .c-tooltip:before, [data-ui-type="Contents_Auth_Pin"] .c-tooltip:before, [data-ui-type="Contents_Auth_Phone"] .c-tooltip:before {
  display: none;
}

[data-ui-type="Contents_Auth_App"] .c-tooltip__desc, [data-ui-type="Contents_Auth_Mail"] .c-tooltip__desc, [data-ui-type="Contents_Auth_Pin"] .c-tooltip__desc, [data-ui-type="Contents_Auth_Phone"] .c-tooltip__desc {
  font-weight: 400;
  font-size: 12px;
  color: #0b83e6;
}

[data-ui-type="Contents_Auth_App"] .c-tooltip__desc em, [data-ui-type="Contents_Auth_Mail"] .c-tooltip__desc em, [data-ui-type="Contents_Auth_Pin"] .c-tooltip__desc em, [data-ui-type="Contents_Auth_Phone"] .c-tooltip__desc em {
  font-weight: 700;
}

[data-ui-type="Contents_Auth_App"] .c-notice, [data-ui-type="Contents_Auth_Mail"] .c-notice, [data-ui-type="Contents_Auth_Pin"] .c-notice, [data-ui-type="Contents_Auth_Phone"] .c-notice {
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_App"] .c-notice, [data-ui-type="Contents_Auth_Mail"] .c-notice, [data-ui-type="Contents_Auth_Pin"] .c-notice, [data-ui-type="Contents_Auth_Phone"] .c-notice {
    margin-top: 52px;
  }
}

[data-ui-type="Contents_Auth_App"] .c-notice__desc, [data-ui-type="Contents_Auth_Mail"] .c-notice__desc, [data-ui-type="Contents_Auth_Pin"] .c-notice__desc, [data-ui-type="Contents_Auth_Phone"] .c-notice__desc, [data-ui-type="Contents_Auth_App"] .c-notice__desc:not(:last-child), [data-ui-type="Contents_Auth_Mail"] .c-notice__desc:not(:last-child), [data-ui-type="Contents_Auth_Pin"] .c-notice__desc:not(:last-child), [data-ui-type="Contents_Auth_Phone"] .c-notice__desc:not(:last-child) {
  margin-bottom: 0;
}

[data-ui-type="Contents_Auth_App"] .c-notice__desc .tel, [data-ui-type="Contents_Auth_Mail"] .c-notice__desc .tel, [data-ui-type="Contents_Auth_Pin"] .c-notice__desc .tel, [data-ui-type="Contents_Auth_Phone"] .c-notice__desc .tel {
  color: inherit;
  text-decoration: none;
}

[data-ui-type="Contents_Auth_App"] .c-bridge, [data-ui-type="Contents_Auth_Mail"] .c-bridge, [data-ui-type="Contents_Auth_Pin"] .c-bridge, [data-ui-type="Contents_Auth_Phone"] .c-bridge {
  margin: 30px 0;
}

[data-ui-type="Contents_Auth_App"] .c-bridge__link-arrow, [data-ui-type="Contents_Auth_Mail"] .c-bridge__link-arrow, [data-ui-type="Contents_Auth_Pin"] .c-bridge__link-arrow, [data-ui-type="Contents_Auth_Phone"] .c-bridge__link-arrow {
  font-weight: bold;
  font-size: 13px;
  color: #333;
  line-height: 16px;
}

[data-ui-type="Contents_Auth_App"] .c-bridge__link-arrow:after, [data-ui-type="Contents_Auth_Mail"] .c-bridge__link-arrow:after, [data-ui-type="Contents_Auth_Pin"] .c-bridge__link-arrow:after, [data-ui-type="Contents_Auth_Phone"] .c-bridge__link-arrow:after {
  display: inline-block;
  margin: 0 2px;
  vertical-align: 1px;
  content: '';
  width: 8px;
  height: 8px;
  border: 1px solid currentColor;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
}

body {
  height: 100%;
}

button {
  border: 0;
  background: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
select,
textarea,
p,
blockquote,
th,
td,
button {
  font-family: -apple-system, BlinkMacSystemFont, "Apple SD Gothic", "Malgun Gothic", arial, sans-serif;
}

@media screen and (min-width: 768px) {
  body,
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  form,
  fieldset,
  input,
  select,
  textarea,
  p,
  blockquote,
  th,
  td,
  button {
    font-family: Lato, "Noto Sans KR", -apple-system, BlinkMacSystemFont, "Apple SD Gothic", "Malgun Gothic", "돋움", "Dotum", arial, sans-serif;
  }
}

@media screen and (min-width: 768px) {
  .mo-only {
    display: none;
  }
}

[data-grid="contents-wrapper"] {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
}

@media screen and (min-width: 768px) {
  [data-grid="contents-wrapper"] {
    display: block;
  }
}

[data-grid="contents-title"] {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 31;
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #ddd;
  background: #fff;
}

@media screen and (min-width: 768px) {
  [data-grid="contents-title"] {
    position: relative;
    max-width: 960px;
    height: 106px;
    margin: 0 auto;
    padding-top: 20px;
    border-color: #333;
  }
}

[data-grid="contents-title"] .c-headline__title {
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 19px;
  color: #000;
}

@media screen and (min-width: 768px) {
  [data-grid="contents-title"] .c-headline__title {
    font-size: 26px;
    color: #111;
    line-height: 38px;
  }
}

[data-grid="contents-title"] .c-headline__logo {
  display: block;
  overflow: hidden;
  font-size: 1px;
  color: transparent;
  text-indent: -999px;
}

@media screen and (min-width: 768px) {
  [data-grid="contents-title"] .c-headline__logo {
    width: 64px;
    height: 28px;
    background-image: url("/assets/img/svg/logo/11st.svg");
    background-size: 64px 28px;
    margin-right: 15px;
  }
}

[data-grid="contents-title"] .c-headline__button-close {
  overflow: hidden;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 36px;
  height: 36px;
  margin-top: -18px;
  font-size: 1px;
  color: transparent;
}

[data-grid="contents-title"] .c-headline__button-close:before, [data-grid="contents-title"] .c-headline__button-close:after {
  content: "";
  display: block;
  position: absolute;
  right: 50%;
  top: 50%;
  height: 26px;
  width: 1.5px;
  margin: -13px 0 0 1.5px;
  background-color: #333;
}

[data-grid="contents-title"] .c-headline__button-close:before {
  transform: rotate(45deg);
}

[data-grid="contents-title"] .c-headline__button-close:after {
  transform: rotate(-45deg);
}

@media screen and (min-width: 768px) {
  [data-grid="contents-title"] .c-headline__button-close {
    right: 0;
    margin-top: -6px;
  }
}

[data-grid="contents-area"] {
  flex: 1;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  padding: 0 16px 20px;
}

@media screen and (min-width: 768px) {
  [data-grid="contents-area"] {
    max-width: 472px;
  }
}

[data-grid="contents-title"] + [data-grid="contents-area"] {
  padding-top: 56px;
}

@media screen and (min-width: 768px) {
  [data-grid="contents-title"] + [data-grid="contents-area"] {
    padding-top: 0;
  }
}

.login-wrapper [data-grid="contents-area"] {
  flex: none;
  max-width: 500px;
  padding: 0 20px 30px;
  box-sizing: border-box;
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-grid="contents-area"] {
    margin-top: 100px;
    padding: 0 50px 30px;
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
  }
}

[data-grid="contents-bottom"] {
  flex-shrink: 0;
  max-width: 375px;
  margin: 0 auto;
  padding: 0 16px 40px;
}

@media screen and (min-width: 768px) {
  [data-grid="contents-bottom"] {
    max-width: 472px;
  }
}

.login-wrapper [data-grid="contents-bottom"] {
  max-width: none;
}

@media screen and (min-width: 768px) {
  .contents-quick-login {
    display: flex;
    min-height: 588px;
  }
  .contents-quick-login [data-grid="contents-area"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 588px;
    padding-bottom: 40px;
  }
}

.login-wrapper.contents-quick-login [data-grid="contents-area"] {
  display: block;
  min-height: auto;
}

.c-box__item {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 57px;
  border: 1px solid #ccc;
}

.c-box__item:only-child {
  height: 58px;
  border-radius: 4px;
}

.c-box__item:not(:only-child):first-child {
  border-radius: 4px 4px 0 0;
}

.c-box__item:not(:only-child):last-child {
  border-radius: 0 0 4px 4px;
}

.c-box__item:not(:only-child):not(:first-child) {
  border-top: 0;
}

.c-box__text,
.c-box .c-input {
  flex: 1;
  background: transparent;
  font-size: 15px;
  color: #111;
}

.c-box__time {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 13px;
  color: #0b83e6;
  white-space: nowrap;
}

.c-box__time dt {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 16px;
  height: 16px;
  background-position: -144px -120px;
  background-size: 234px 198px;
  flex-shrink: 0;
  overflow: hidden;
  margin-right: 4px;
  font-size: 6px;
  color: transparent;
  user-select: none;
}

.c-box__time dd {
  width: 33px;
  height: 15px;
}

.c-box__button-delete {
  flex-shrink: 0;
  position: relative;
  height: 100%;
  padding: 0 16px;
}

.c-box__button-delete .icon {
  display: block;
  overflow: hidden;
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #bbb;
  font-size: 1px;
  color: transparent;
}

.c-box__button-delete .icon:before, .c-box__button-delete .icon:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 10px;
  background: #fff;
}

.c-box__button-delete .icon:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.c-box__button-delete .icon:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.c-box-wrap > dt {
  display: block;
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 22px;
}

.c-buttons {
  display: flex;
}

.c-buttons .c-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 48px;
  border-radius: 4px;
  font-size: 17px;
}

@media screen and (min-width: 768px) {
  .c-buttons .c-button {
    padding-bottom: 1px;
    font-size: 16px;
    line-height: 18px;
  }
  .c-buttons .c-button--normal {
    color: #111;
  }
}

.c-buttons .c-button:not(:first-child) {
  margin-left: 8px;
}

@media screen and (min-width: 768px) {
  .c-buttons .c-button:not(:first-child) {
    margin-left: 10px;
  }
}

.c-buttons--fixed {
  position: fixed;
  left: 16px;
  bottom: 16px;
  right: 16px;
}

@media screen and (min-width: 768px) {
  .c-buttons--fixed {
    position: static;
    margin-top: 24px;
  }
}

.c-iconinfo {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 24px;
  height: 24px;
  text-indent: -9999px;
  color: transparent;
}

.c-iconinfo:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.c-iconinfo--help:after {
  width: 14px;
  height: 14px;
  background-image: url("/assets/img/svg/ic-help.svg");
  background-size: 14px 14px;
}

.c-dialog {
  display: none;
  z-index: 105;
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  width: 280px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 14px 18px 50px rgba(0, 0, 0, 0.3), 0 5px 10px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  transform: translate(-50%, -50%);
}

.c-dialog__title {
  display: none;
  padding: 0 16px;
  border-bottom: 1px solid #eee;
  font-weight: bold;
  color: #111;
  text-align: center;
  word-break: break-all;
}

@media screen and (min-width: 768px) {
  .c-dialog__title {
    display: block;
    padding: 21px 78px 20px;
    font-size: 19px;
    line-height: 28px;
  }
}

.c-dialog__content {
  margin: 24px 16px 25px;
  font-size: 15px;
  line-height: 20px;
}

.c-dialog__content-info {
  font-size: 15px;
  font-weight: bold;
  color: #111;
}

.c-dialog__content-info strong {
  display: block;
}

.c-dialog__content-info .title {
  display: block;
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 21px;
}

.c-dialog__content-info + .c-dialog__text {
  margin-top: 13px;
  font-size: 14px;
  color: #666;
}

.c-dialog__content-point {
  color: #ff0038;
}

.c-dialog__text:only-child {
  margin: 0 -6px;
  color: #333;
}

.c-dialog__buttons {
  display: flex;
  position: static;
  height: 48px;
  margin: 0;
  padding: 0;
  border-top: 1px solid #f4f4f4;
}

.c-dialog__buttons .c-dialog__button-point,
.c-dialog__buttons .c-dialog__button-normal {
  display: block;
  flex: 1;
  height: 48px;
  padding: 0;
  border: 0;
  border-right: 1px solid #fafafa;
  background: #fff;
  font-size: 16px;
  color: #333;
  line-height: 48px;
  text-align: center;
  box-sizing: border-box;
}

.c-dialog__buttons .c-dialog__button-point:last-child,
.c-dialog__buttons .c-dialog__button-normal:last-child {
  border-right: none;
}

.c-dialog__buttons .c-dialog__button-point {
  color: #ff0038;
}

.c-dialog__close {
  height: 70px;
  width: 77px;
  display: none;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  margin: 0;
  border: 0;
  color: transparent;
  line-height: 200px;
}

.c-dialog__close:before, .c-dialog__close:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 50px;
  margin: -25px 0 0 -2px;
  background: #111;
  content: "";
}

.c-dialog__close:before {
  transform: scale(0.5) rotate(45deg);
}

.c-dialog__close:after {
  transform: scale(0.5) rotate(-45deg);
}

@media screen and (min-width: 768px) {
  .c-dialog__close {
    display: block;
  }
}

.c-dialog--center {
  text-align: center;
}

.c-dialog--info {
  overflow-x: hidden;
  overflow-y: auto;
  width: 315px;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 359px) {
  .c-dialog--info {
    right: 24px;
    left: 24px;
    width: auto;
    transform: translateY(-50%);
  }
}

.c-dialog--large {
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 768px) {
  .c-dialog {
    width: 340px;
  }
  .c-dialog__content {
    font-size: 16px;
    line-height: 24px;
  }
  .c-dialog__content-info {
    line-height: 20px;
  }
  .c-dialog__content-info .title {
    font-size: 19px;
    font-weight: 900;
    line-height: 23px;
  }
  .c-dialog__content-point {
    color: #ff0038;
  }
  .c-dialog__text {
    margin-top: 16px;
  }
  .c-dialog--info {
    top: 235px;
    width: 500px;
    max-width: 500px;
    transform: translate(-50%, 0);
  }
  .c-dialog--large {
    width: auto;
  }
}

.c-input {
  display: block;
  width: 100%;
  padding: 0 16px;
  border: 0;
  color: #111;
  caret-color: #ff0038;
}

.c-input::-ms-clear, .c-input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.c-input::placeholder {
  color: #999;
}

@media screen and (min-width: 768px) {
  .c-input::placeholder {
    color: #949494;
  }
}

.c-input[readonly] {
  background-color: #fafafa;
  pointer-events: none;
}

.c-input:-webkit-autofill, .c-input:-webkit-autofill:hover, .c-input:-webkit-autofill:focus, .c-input:-webkit-autofill:active {
  -webkit-text-fill-color: #111;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.c-box .c-input {
  flex: 1;
  min-width: 0;
  height: 100%;
}

.c-radio--check + label {
  padding-left: 27px;
}

@media screen and (min-width: 768px) {
  .c-radio--check + label {
    padding-left: 32px;
  }
}

.c-radio--check + label:before {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 14px;
  height: 14px;
  background-position: -216px -4px;
  background-size: 234px 198px;
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
  border: 0;
}

@media screen and (min-width: 768px) {
  .c-radio--check + label:before {
    width: 18px;
    height: 18px;
    background-position: -276.92308px -5.13131px;
    background-size: 300px 254px;
    left: 0;
  }
}

.c-radio--check + label:after {
  display: none;
}

.c-radio--check:checked + label:before {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 14px;
  height: 14px;
  background-position: -216px -26px;
  background-size: 234px 198px;
}

@media screen and (min-width: 768px) {
  .c-radio--check:checked + label:before {
    width: 18px;
    height: 18px;
    background-position: -276.92308px -33.35354px;
    background-size: 300px 254px;
  }
}

.c-radio--check:checked + label:after {
  display: none;
}

.c-infotip {
  position: relative;
}

.c-infotip__layer {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-width: 400px;
  z-index: 20;
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.36);
  color: #333;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .c-infotip__layer {
    padding: 17px 20px 20px;
    color: #111;
  }
}

.c-infotip__layer--show {
  display: block;
}

.c-infotip dt {
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
}

.c-infotip dt:first-child {
  padding-right: 26px;
}

@media screen and (min-width: 768px) {
  .c-infotip dt {
    font-size: 16px;
    line-height: 23px;
  }
}

.c-infotip dd, .c-infotip__desc {
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
}

.c-infotip dd:first-child, .c-infotip__desc:first-child {
  margin-top: 0;
  padding-right: 26px;
}

@media screen and (min-width: 768px) {
  .c-infotip dd, .c-infotip__desc {
    font-size: 15px;
    line-height: 22px;
  }
}

.c-infotip__button-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
}

.c-infotip__button-close:before, .c-infotip__button-close:after {
  content: "";
  display: block;
  position: absolute;
  right: 50%;
  top: 50%;
  height: 16px;
  width: 1px;
  margin: -8px 0 0 1px;
  background-color: #333;
}

.c-infotip__button-close:before {
  transform: rotate(45deg);
}

.c-infotip__button-close:after {
  transform: rotate(-45deg);
}

@media screen and (min-width: 768px) {
  .c-infotip__button-close {
    top: 16px;
    right: 16px;
  }
}

.c-infotip__point {
  color: #ff0038;
}

.c-notice {
  font-size: 13px;
}

.c-notice__title {
  display: block;
  margin-bottom: 6px;
  font-weight: bold;
  color: #333;
  line-height: 16px;
}

@media screen and (min-width: 768px) {
  .c-notice__title {
    line-height: 19px;
  }
}

.c-notice__desc {
  line-height: 18px;
  color: #949494;
}

.c-notice__desc:not(:last-child) {
  margin-bottom: 8px;
}

@media screen and (min-width: 768px) {
  .c-notice__desc:not(:last-child) {
    margin-bottom: 4px;
  }
}

.c-notice__desc em {
  color: #111;
}

.c-notice__desc .number {
  font-weight: bold;
}

.c-notice a, .c-notice__link {
  color: #0b83e6;
  line-height: 18px;
  text-decoration: underline;
}

.c-tooltip-wrap {
  display: none;
  position: relative;
}

.c-tooltip-wrap--show {
  display: block;
}

.c-tooltip {
  position: absolute;
  top: 100%;
  left: -32px;
  z-index: 10;
  margin-top: 17px;
  border-radius: 4px;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
  background-color: #fff;
  padding: 10px 62px 10px 14px;
  letter-spacing: -0.1px;
  line-height: 21px;
  white-space: nowrap;
}

.c-tooltip:before {
  position: absolute;
  top: -6px;
  left: 37px;
  width: 11px;
  height: 11px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-width: 1px 1px 0 0;
  background-color: #fff;
  transform: rotate(-45deg);
  content: "";
}

.c-tooltip__desc {
  font-size: 14px;
  color: #090909;
}

.c-tooltip__desc .point {
  color: #fc3646;
}

.c-tooltip__link {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  font-size: 12px;
  color: rgba(17, 17, 17, 0.8);
}

.c-tooltip__link:after {
  content: '';
  width: 6px;
  height: 6px;
  border: 1px solid currentColor;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
  margin-left: 1px;
}

.c-tooltip__button-close {
  position: absolute;
  top: 14px;
  right: 15px;
  width: 13px;
  height: 13px;
}

.c-tooltip__button-close:before, .c-tooltip__button-close:after {
  content: "";
  display: block;
  position: absolute;
  right: 50%;
  top: 50%;
  height: 16px;
  width: 1px;
  margin: -8px 0 0 1px;
  background-color: #333;
}

.c-tooltip__button-close:before {
  transform: rotate(45deg);
}

.c-tooltip__button-close:after {
  transform: rotate(-45deg);
}

.c-tooltip--arrow {
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 328px;
  margin-top: 19px;
  padding: 23px;
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 0;
  box-shadow: none;
  line-height: 20px;
}

.c-tooltip--arrow:before, .c-tooltip--arrow:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-width: 0 8px 11px;
  background: 0;
}

.c-tooltip--arrow:before {
  top: -11px;
  border-bottom-color: #ddd;
}

.c-tooltip--arrow:after {
  top: -9px;
  border-bottom-color: #fafafa;
}

.c-tooltip--arrow .c-button--normal {
  width: 104px;
  height: 32px;
  border-color: #ddd;
  border-radius: 4px;
  font-size: 13px;
  line-height: 30px;
  color: #111;
}

[data-ui-type="Banner_Login"] {
  overflow: hidden;
  max-width: 500px;
  margin: 0 4px 20px;
  border-radius: 4px;
}

[data-ui-type="Banner_Login"] a {
  display: block;
}

[data-ui-type="Banner_Login"] img {
  width: 100%;
  min-height: 60px;
  vertical-align: top;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Banner_Login"] {
    margin-left: auto;
    margin-right: auto;
  }
}

[data-ui-type="Cap_Login_Adult"] {
  margin-bottom: 30px;
  padding-top: 40px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Cap_Login_Adult"] {
    margin-bottom: 24px;
    padding-top: 50px;
  }
}

[data-ui-type="Cap_Login_Adult"] .c-headline {
  text-align: center;
}

[data-ui-type="Cap_Login_Adult"] .c-headline__logo:before {
  content: "";
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 40px;
  height: 40px;
  background-position: -4px -4px;
  background-size: 234px 198px;
  display: block;
  margin: 0 auto;
}

[data-ui-type="Cap_Login_Adult"] .c-headline__desc {
  margin-top: 10px;
  font-size: 15px;
  line-height: 20px;
  color: #333;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Cap_Login_Adult"] .c-headline__desc {
    margin: 12px 0 4px;
    color: #111;
  }
}

[data-ui-type="Cap_Login_Adult"] .c-headline__button {
  margin-top: 6px;
  font-size: 14px;
  color: #949494;
  line-height: 16px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Cap_Login_Adult"] .c-headline__button {
    line-height: 18px;
  }
}

[data-ui-type="Cap_Login_Adult"] .c-headline__button:after {
  content: "";
  content: '';
  width: 7.5px;
  height: 7.5px;
  border: 1px solid #bbb;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
  display: inline-block;
  margin: -2px 2px 1px 0;
  vertical-align: middle;
}

[data-ui-type="Cap_Login_Adult"] .c-infotip__layer {
  margin-top: 37px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Cap_Login_Adult"] .c-infotip__layer {
    margin-top: 24px;
  }
}

[data-ui-type="Cap_Login_Adult"] .c-button-close {
  overflow: hidden;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 36px;
  height: 36px;
  font-size: 1px;
  color: transparent;
}

[data-ui-type="Cap_Login_Adult"] .c-button-close:before, [data-ui-type="Cap_Login_Adult"] .c-button-close:after {
  content: "";
  display: block;
  position: absolute;
  right: 50%;
  top: 50%;
  height: 26px;
  width: 1.5px;
  margin: -13px 0 0 1.5px;
  background-color: #333;
}

[data-ui-type="Cap_Login_Adult"] .c-button-close:before {
  transform: rotate(45deg);
}

[data-ui-type="Cap_Login_Adult"] .c-button-close:after {
  transform: rotate(-45deg);
}

[data-ui-type="Cap_Login_Logo"] .c-headline {
  padding-top: 40px;
  text-align: center;
}

[data-ui-type="Cap_Login_Logo"] .c-headline__logo {
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 88px;
  vertical-align: top;
}

[data-ui-type="Cap_Login_Logo"] .c-headline__logo:before {
  content: "";
  display: block;
  width: 88px;
  height: 38px;
  background-image: url("/assets/img/svg/logo/11st.svg");
  background-size: 88px 38px;
}

[data-ui-type="Cap_Login_Logo"] .c-headline__logo a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

[data-ui-type="Cap_Login_Logo"] .c-headline__desc {
  font-size: 15px;
  line-height: 21px;
  color: #666;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Cap_Login_Logo"] .c-headline__logo {
    height: 123px;
  }
  [data-ui-type="Cap_Login_Logo"] .c-headline__logo:before {
    width: 100px;
    height: 43px;
    background-image: url("/assets/img/svg/logo/11st.svg");
    background-size: 100px 43px;
  }
  [data-ui-type="Cap_Login_Logo"] .c-headline__desc {
    font-size: 18px;
    color: #333;
    letter-spacing: -1px;
    line-height: 24px;
  }
}

[data-ui-type="Cap_Login_Logo"] .c-button-close {
  overflow: hidden;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 36px;
  height: 36px;
  font-size: 1px;
  color: transparent;
}

[data-ui-type="Cap_Login_Logo"] .c-button-close:before, [data-ui-type="Cap_Login_Logo"] .c-button-close:after {
  content: "";
  display: block;
  position: absolute;
  right: 50%;
  top: 50%;
  height: 26px;
  width: 1.5px;
  margin: -13px 0 0 1.5px;
  background-color: #333;
}

[data-ui-type="Cap_Login_Logo"] .c-button-close:before {
  transform: rotate(45deg);
}

[data-ui-type="Cap_Login_Logo"] .c-button-close:after {
  transform: rotate(-45deg);
}

.login-wrapper [data-ui-type="Cap_Login_Logo"] .c-headline {
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-ui-type="Cap_Login_Logo"] .c-headline {
    padding-top: 50px;
    margin-bottom: 40px;
  }
}

.login-wrapper [data-ui-type="Cap_Login_Logo"] .c-headline__logo {
  height: auto;
}

.login-wrapper [data-ui-type="Cap_Login_Logo"] .c-headline__logo:before {
  width: 60px;
  height: 26px;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-ui-type="Cap_Login_Logo"] .c-headline__logo:before {
    width: 75px;
    height: 32px;
  }
}

.login-wrapper [data-ui-type="Cap_Login_Logo"] .c-button-close:before, .login-wrapper [data-ui-type="Cap_Login_Logo"] .c-button-close:after {
  height: 24px;
  margin-top: -12px;
}

[data-ui-type="Cap_Login_Mobile"] {
  margin: 30px 0;
}

[data-ui-type="Cap_Login_Mobile"] .c-headline {
  text-align: center;
}

[data-ui-type="Cap_Login_Mobile"] .c-headline__desc {
  font-size: 13px;
  color: #949494;
  line-height: 19px;
}

[data-ui-type="Cap_Login_Mobile"] .c-buttons {
  position: relative;
  margin-top: 16px;
}

[data-ui-type="Cap_Login_Mobile"] .c-buttons .c-button--app {
  height: 52px;
  border-radius: 4px;
  background: linear-gradient(97deg, #ff5a2e 0%, #ff0038 49.47%, #ff00ef 100%);
  font-size: 17px;
  font-weight: 700;
  color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
}

[data-ui-type="Cap_Login_Mobile"] .c-bubble {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 18px;
  box-sizing: content-box;
  padding-bottom: 4px;
  background: url(/assets/img/svg/member/bg-bubble.svg) no-repeat;
  background-size: auto 100%;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
  font-weight: 400;
  font-size: 11px;
  color: #ff0038;
  line-height: 20px;
  text-align: center;
  vertical-align: top;
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 768px) {
  [data-ui-type="Cap_Login_Mobile"] .c-bubble {
    width: 63px;
    padding-bottom: 5px;
  }
}

[data-ui-type="Cap_Box_Auth"] {
  padding: 32px 0;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Cap_Box_Auth"] {
    max-width: 350px;
    margin: 0 auto;
    padding-top: 40px;
  }
}

.join-wrapper [data-ui-type="Cap_Box_Auth"] {
  max-width: none;
  padding: 24px 0 0;
}

.join-wrapper [data-ui-type="Cap_Box_Auth"] .c-option {
  margin: 0;
  padding: 9px 0;
}

[data-ui-type="Cap_Box_Auth"] .c-headline {
  color: #111;
  line-height: 26px;
}

[data-ui-type="Cap_Box_Auth"] .c-headline__title {
  font-weight: bold;
  font-size: 20px;
}

[data-ui-type="Cap_Box_Auth"] .c-headline__desc {
  margin-top: 4px;
  font-size: 16px;
}

[data-ui-type="Cap_Box_Auth"] .c-account {
  display: flex;
  margin-bottom: 31px;
}

[data-ui-type="Cap_Box_Auth"] .c-account__type {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-position: -86.15385px -172.36364px;
  background-size: 280px 237px;
  overflow: hidden;
  margin-right: 10px;
  font-size: 1px;
  color: transparent;
}

[data-ui-type="Cap_Box_Auth"] .c-account__id {
  font-size: 20px;
  font-weight: 700;
  color: #111;
  line-height: 24px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Cap_Box_Auth"] .c-account__id {
    font-weight: 900;
  }
}

[data-ui-type="Cap_Box_Auth"] .c-option {
  display: flex;
  margin-top: 10px;
}

[data-ui-type="Cap_Box_Auth"] .c-option__item:not(:first-child) {
  margin-left: 33px;
}

@media screen and (max-width: 359px) {
  [data-ui-type="Cap_Box_Auth"] .c-option__item {
    flex: 1;
    max-width: 37%;
    white-space: nowrap;
  }
  [data-ui-type="Cap_Box_Auth"] .c-option__item:not(:first-child) {
    margin-left: 18px;
  }
}

[data-ui-type="Cap_Box_Auth"] .c-radio + label {
  font-size: 16px;
  color: #111;
}

[data-ui-type="Cap_Box_Auth"] .c-radio:checked + label {
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Cap_Box_Auth"] .c-tab {
    margin-bottom: 20px;
  }
}

[data-ui-type="Contents_App_Permission"] {
  max-width: 350px;
  margin: 0 auto;
  padding: 32px 0;
}

[data-ui-type="Contents_App_Permission"] .c-headline__title {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #333;
}

[data-ui-type="Contents_App_Permission"] .c-headline__desc {
  margin-top: 8px;
  font-size: 15px;
  line-height: 21px;
  color: #666;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_App_Permission"] .c-headline__desc {
    font-size: 14px;
  }
}

[data-ui-type="Contents_App_Permission"] .c-box {
  overflow: visible;
  position: relative;
  margin-top: 32px;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

[data-ui-type="Contents_App_Permission"] .c-box__time {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
}

[data-ui-type="Contents_App_Permission"] .c-list {
  font-size: 13px;
  line-height: 18px;
}

[data-ui-type="Contents_App_Permission"] .c-list dt {
  float: left;
  width: 63px;
}

[data-ui-type="Contents_App_Permission"] .c-list dt:not(:first-of-type) {
  margin-top: 8px;
}

[data-ui-type="Contents_App_Permission"] .c-list dd {
  overflow: hidden;
  color: #333;
}

[data-ui-type="Contents_App_Permission"] .c-list dd:not(:first-of-type) {
  margin-top: 8px;
}

[data-ui-type="Contents_App_Permission"] .c-buttons {
  margin-top: 39px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_App"] {
    max-width: 350px;
    margin: 0 auto;
  }
}

[data-ui-type="Contents_Auth_App"] .c-box__item {
  padding: 0 15px;
  border-color: #ddd;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_App"] .c-box__item {
    padding: 0 19px;
  }
}

[data-ui-type="Contents_Auth_App"] .c-box__item:only-child {
  background: #fafafa;
}

[data-ui-type="Contents_Auth_App"] .c-box__device {
  flex: 1;
  position: relative;
  min-width: 0;
}

[data-ui-type="Contents_Auth_App"] .c-box__date {
  margin-left: 12px;
  font-size: 13px;
  color: #666;
  text-align: right;
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_App"] .c-box .c-radio + label {
    margin-left: -7px;
  }
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_App"] .c-box .c-radio--check + label {
    margin-left: 0;
  }
}

[data-ui-type="Contents_Auth_App"] .c-box .label {
  display: block;
  overflow: hidden;
  font-size: 15px;
  color: #111;
  text-overflow: ellipsis;
  white-space: nowrap;
}

[data-ui-type="Contents_Auth_App"] .c-buttons {
  margin-top: 14px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_Mail"] {
    max-width: 350px;
    margin: 0 auto;
  }
}

[data-ui-type="Contents_Auth_Mail"] .c-box__item {
  border-color: #ddd;
}

[data-ui-type="Contents_Auth_Mail"] .c-box__item:only-child {
  background: #fafafa;
}

[data-ui-type="Contents_Auth_Mail"] .c-box__time {
  margin-right: 15px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_Mail"] .c-box__time {
    margin-right: 19px;
  }
}

[data-ui-type="Contents_Auth_Mail"] .c-box .c-input {
  padding-left: 15px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_Mail"] .c-box .c-input {
    padding-left: 19px;
  }
}

[data-ui-type="Contents_Auth_Mail"] .c-buttons {
  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_Mail"] .c-buttons {
    margin-top: 14px;
  }
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_Pin"] {
    max-width: 350px;
    margin: 0 auto;
  }
}

[data-ui-type="Contents_Auth_Pin"] .c-box__item {
  border-color: #ddd;
}

[data-ui-type="Contents_Auth_Pin"] .c-box__time {
  flex-shrink: 0;
}

[data-ui-type="Contents_Auth_Pin"] .c-box .c-input {
  padding-left: 15px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_Pin"] .c-box .c-input {
    padding-left: 19px;
  }
}

[data-ui-type="Contents_Auth_Pin"] .c-box .c-button {
  flex-shrink: 0;
  width: 60px;
  height: 32px;
  margin: 0 12px 0 8px;
  border-color: #bbb;
  border-radius: 360px;
  font-size: 14px;
}

[data-ui-type="Contents_Auth_Pin"] .c-box .c-button[disabled] {
  border-color: rgba(204, 204, 204, 0.2);
  background-color: #fafafa;
  color: #ccc;
}

[data-ui-type="Contents_Auth_Pin"] .c-buttons {
  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_Pin"] .c-buttons {
    margin-top: 14px;
  }
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_Phone"] {
    max-width: 350px;
    margin: 0 auto;
  }
}

[data-ui-type="Contents_Auth_Phone"] .c-box__item {
  border-color: #ddd;
}

[data-ui-type="Contents_Auth_Phone"] .c-box__item:only-child {
  background: #fafafa;
}

[data-ui-type="Contents_Auth_Phone"] .c-box__time {
  margin-right: 15px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_Phone"] .c-box__time {
    margin-right: 19px;
  }
}

[data-ui-type="Contents_Auth_Phone"] .c-box .c-input {
  padding-left: 15px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_Phone"] .c-box .c-input {
    padding-left: 19px;
  }
}

[data-ui-type="Contents_Auth_Phone"] .c-buttons {
  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_Phone"] .c-buttons {
    margin-top: 14px;
  }
}

[data-ui-type="Contents_Auth_Phone"] .c-buttons .c-button--kakao:not(:disabled) {
  background: #fbe100;
  color: #381e20;
}

[data-ui-type="Contents_Auth_Phone"] .c-buttons .c-button--kakao:disabled:before {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 18px;
  height: 18px;
  background-position: -188px -140px;
  background-size: 234px 198px;
}

[data-ui-type="Contents_Auth_Phone"] .c-buttons .c-button--kakao:before {
  content: "";
  display: block;
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 18px;
  height: 18px;
  background-position: -188px -114px;
  background-size: 234px 198px;
  margin-right: 7px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Auth_Phone"] .c-buttons .c-button--kakao:before {
    margin-top: 2px;
  }
}

[data-ui-type="Contents_Auth_Phone"] .c-buttons .c-button--kakao em {
  font-weight: bold;
}

[data-ui-type="Contents_Login_Footer"] {
  padding: 0 0 85px;
}

[data-ui-type="Contents_Login_Footer"] .copyright {
  font-size: 12px;
  color: #949494;
  line-height: 18px;
  text-align: center;
}

[data-ui-type="Contents_Login_Form"] {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Form"] {
    max-width: 328px;
    margin: 0 auto;
  }
}

[data-ui-type="Contents_Login_Form"] .c-box__item {
  height: 55px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Form"] .c-box__item {
    height: 57px;
  }
}

[data-ui-type="Contents_Login_Form"] .c-box__item--active {
  z-index: 1;
  border-color: #ff0038;
}

[data-ui-type="Contents_Login_Form"] .c-box__item--active:not(:first-child) {
  box-shadow: 0 -1px 0 #ff0038, inset 0 0.2px 0 #fff;
}

[data-ui-type="Contents_Login_Form"] .c-box__item .c-input {
  outline: none;
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Form"] .c-box__item .c-input {
    font-size: 15px;
  }
}

[data-ui-type="Contents_Login_Form"] .c-box__item .c-input::placeholder {
  color: #bbb;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Form"] .c-box__item .c-input::placeholder {
    color: #949494;
  }
}

[data-ui-type="Contents_Login_Form"] .c-box__button-delete, [data-ui-type="Contents_Login_Form"] .c-box__button-masking {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Form"] .c-box__button-delete {
    padding-right: 24px;
  }
}

[data-ui-type="Contents_Login_Form"] .c-box__button-masking {
  height: 100%;
  padding: 0 13px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Form"] .c-box__button-masking {
    padding-right: 20px;
  }
}

[data-ui-type="Contents_Login_Form"] .c-box__button-masking:before {
  display: block;
  content: "";
}

[data-ui-type="Contents_Login_Form"] .c-box__button-masking[aria-pressed="false"]:before {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 28px;
  height: 28px;
  background-position: -144px -84px;
  background-size: 234px 198px;
}

[data-ui-type="Contents_Login_Form"] .c-box__button-masking[aria-pressed="true"]:before {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 28px;
  height: 28px;
  background-position: -4px -144px;
  background-size: 234px 198px;
}

[data-ui-type="Contents_Login_Form"] .c-error__message {
  margin: 16px 4px;
  font-size: 13px;
  line-height: 16px;
  color: #ff0038;
  word-break: keep-all;
}

[data-ui-type="Contents_Login_Form"] .c-buttons {
  margin-top: 12px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Form"] .c-buttons {
    margin-top: 10px;
  }
}

[data-ui-type="Contents_Login_Form"] .c-buttons .c-button {
  height: 56px;
}

[data-ui-type="Contents_Login_Form"] .c-util {
  display: flex;
  margin-top: 12px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Form"] .c-util {
    margin-top: 8px;
  }
}

[data-ui-type="Contents_Login_Form"] .c-util__item {
  font-size: 0;
}

[data-ui-type="Contents_Login_Form"] .c-util__item:nth-child(2n) {
  flex: 1;
  text-align: right;
}

[data-ui-type="Contents_Login_Form"] .c-util__item .c-checkbox + label {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Form"] .c-util__item .c-checkbox + label {
    font-size: 13px;
    color: #111;
  }
}

[data-ui-type="Contents_Login_Form"] .c-util__item .c-iconinfo--help {
  height: 22px;
  vertical-align: top;
}

[data-ui-type="Contents_Login_Form"] .c-util__link {
  display: inline-block;
  position: relative;
  vertical-align: top;
  font-size: 14px;
  line-height: 22px;
  color: #333;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Form"] .c-util__link {
    font-size: 13px;
    color: #111;
  }
}

[data-ui-type="Contents_Login_Form"] .c-util__link:not(:first-child) {
  margin-left: 24px;
}

[data-ui-type="Contents_Login_Form"] .c-util__link:not(:first-child):before {
  content: "";
  position: absolute;
  top: 50%;
  left: -12px;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background: #ccc;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Form"] .c-util__link:not(:first-child) {
    margin-left: 17px;
  }
  [data-ui-type="Contents_Login_Form"] .c-util__link:not(:first-child):before {
    left: -9px;
    margin-top: -6px;
  }
}

[data-ui-type="Contents_Login_Form"] .c-captcha {
  margin: 16px 0 20px;
}

[data-ui-type="Contents_Login_Form"] .c-captcha iframe {
  margin: 0 auto;
  border-radius: 4px;
}

[data-ui-type="Contents_Login_Form"] .c-captcha--error iframe {
  border: 1px solid #ff0038;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] {
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-ui-type="Contents_Login_Form"] {
    max-width: none;
    margin-top: 22px;
  }
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-box__item {
  height: 48px;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-box__item:not(.c-box__item--active) {
  border-color: #ddd;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-box__item:not(.c-box__item--active):first-child {
  border-bottom-color: #eee;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-box__item .c-input::placeholder {
  font-size: 15px;
  color: #949494;
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-ui-type="Contents_Login_Form"] .c-box__item .c-input {
    padding-bottom: 2px;
  }
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-box__button-delete {
  padding: 0 18px;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-box__button-masking {
  padding: 0 15px;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-box__button-masking[aria-pressed="false"]:before {
  width: 24px;
  height: 24px;
  background-position: -123.07692px -71.69697px;
  background-size: 200px 169px;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-box__button-masking[aria-pressed="true"]:before {
  width: 24px;
  height: 24px;
  background-position: -3.4188px -122.90909px;
  background-size: 200px 169px;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-error__message {
  margin: 12px 0;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-ui-type="Contents_Login_Form"] .c-error__message {
    line-height: 19px;
  }
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-buttons .c-button--submit {
  height: 52px;
  font-weight: 700;
  border-color: #ff0038;
  background: #ff0038;
  color: #fff;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-buttons .c-button--submit[disabled] {
  border-color: #ccc;
  background-color: #ccc;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-util {
  position: relative;
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-ui-type="Contents_Login_Form"] .c-util {
    margin-top: 12px;
  }
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-util__item .c-iconinfo--help {
  width: 20px;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-util .c-checkbox + label {
  font-size: 13px;
  color: #000;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-tooltip {
  left: -30px;
  margin-top: 12px;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-tooltip:before {
  left: 35px;
}

.login-wrapper [data-ui-type="Contents_Login_Form"] .c-captcha {
  margin: 12px 0;
}

[data-ui-type="Contents_Login_Form"] .c-passkey {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  margin-top: 24px;
  padding: 0 16px;
  border-radius: 4px;
  background: #f8f8f8;
}

[data-ui-type="Contents_Login_Form"] .c-passkey__item {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

[data-ui-type="Contents_Login_Form"] .c-passkey__text {
  padding-bottom: 1px;
  font-size: 13px;
  color: #333;
}

[data-ui-type="Contents_Login_Form"] .c-passkey__button {
  display: inline-flex;
  align-items: center;
  height: 30px;
  padding: 0 12px 1px;
  border-radius: 360px;
  background: #ff0038;
  font-size: 14px;
  color: #fff;
  vertical-align: top;
}

[data-ui-type="Contents_Login_Sns"] {
  margin-top: 56px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Sns"] {
    margin-top: 43px;
  }
}

[data-ui-type="Contents_Login_Sns"] .c-headline__desc {
  font-size: 13px;
  color: #949494;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Sns"] .c-headline__desc {
    color: #999;
  }
}

[data-ui-type="Contents_Login_Sns"] .c-sns {
  overflow: hidden;
  position: relative;
  width: 264px;
  height: 36px;
  margin: 10px auto 0;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Sns"] .c-sns {
    width: 290px;
    height: 40px;
    margin-top: 13px;
  }
}

[data-ui-type="Contents_Login_Sns"] .c-sns--show {
  height: 96px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Sns"] .c-sns--show {
    height: 112px;
  }
}

[data-ui-type="Contents_Login_Sns"] .c-sns--show .c-sns__button-toggle:before {
  margin: 4px auto 0;
  transform: rotate(315deg);
}

[data-ui-type="Contents_Login_Sns"] .c-sns--col3 {
  width: 210px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Sns"] .c-sns--col5 {
    width: 300px;
    padding-right: 7px;
    box-sizing: content-box;
  }
  [data-ui-type="Contents_Login_Sns"] .c-sns--col5 .c-sns__item {
    margin: 0 13px 28px 0;
  }
  [data-ui-type="Contents_Login_Sns"] .c-sns--col5 .c-sns__item:last-child {
    margin-right: 0;
    margin-left: 7px;
  }
}

[data-ui-type="Contents_Login_Sns"] .c-sns__list {
  font-size: 0;
}

[data-ui-type="Contents_Login_Sns"] .c-sns__item {
  display: inline-block;
  margin: 0 10px 24px;
  vertical-align: top;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Sns"] .c-sns__item {
    margin-bottom: 32px;
  }
}

[data-ui-type="Contents_Login_Sns"] .c-sns__link {
  display: block;
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
}

[data-ui-type="Contents_Login_Sns"] .c-sns__link--kakao {
  width: 36px;
  height: 36px;
  background-position: -100px -48px;
  background-size: 234px 198px;
}

[data-ui-type="Contents_Login_Sns"] .c-sns__link--tid {
  width: 36px;
  height: 36px;
  background-position: -144px -4px;
  background-size: 234px 198px;
}

[data-ui-type="Contents_Login_Sns"] .c-sns__link--naver {
  width: 36px;
  height: 36px;
  background-position: -4px -100px;
  background-size: 234px 198px;
}

[data-ui-type="Contents_Login_Sns"] .c-sns__link--mobile {
  width: 36px;
  height: 36px;
  background-position: -46.66667px -46.74747px;
  background-size: 210px 178px;
}

[data-ui-type="Contents_Login_Sns"] .c-sns__link--payco {
  width: 36px;
  height: 36px;
  background-position: -48px -100px;
  background-size: 234px 198px;
}

[data-ui-type="Contents_Login_Sns"] .c-sns__link--apple {
  width: 36px;
  height: 36px;
  background-position: -100px -4px;
  background-size: 234px 198px;
}

[data-ui-type="Contents_Login_Sns"] .c-sns__link--facebook {
  width: 36px;
  height: 36px;
  background-position: -3.58974px -46.74747px;
  background-size: 210px 178px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Sns"] .c-sns__link--kakao {
    width: 40px;
    height: 40px;
    background-position: -111.11111px -53.33333px;
    background-size: 260px 220px;
  }
  [data-ui-type="Contents_Login_Sns"] .c-sns__link--tid {
    width: 40px;
    height: 40px;
    background-position: -160px -4.44444px;
    background-size: 260px 220px;
  }
  [data-ui-type="Contents_Login_Sns"] .c-sns__link--naver {
    width: 40px;
    height: 40px;
    background-position: -4.44444px -111.11111px;
    background-size: 260px 220px;
  }
  [data-ui-type="Contents_Login_Sns"] .c-sns__link--mobile {
    width: 40px;
    height: 40px;
    background-position: -52px -52px;
    background-size: 234px 198px;
  }
  [data-ui-type="Contents_Login_Sns"] .c-sns__link--payco {
    width: 40px;
    height: 40px;
    background-position: -53.33333px -111.11111px;
    background-size: 260px 220px;
  }
  [data-ui-type="Contents_Login_Sns"] .c-sns__link--apple {
    width: 40px;
    height: 40px;
    background-position: -111.11111px -4.44444px;
    background-size: 260px 220px;
  }
  [data-ui-type="Contents_Login_Sns"] .c-sns__link--facebook-n {
    width: 40px;
    height: 40px;
    background-position: -4px -52px;
    background-size: 234px 198px;
  }
  [data-ui-type="Contents_Login_Sns"] .c-sns__link--facebook {
    width: 40px;
    height: 40px;
    background-position: -4px -52px;
    background-size: 234px 198px;
  }
}

[data-ui-type="Contents_Login_Sns"] .c-sns__link > span {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  margin: -1px;
  width: 1px;
  height: 1px;
  font-size: 6px;
  line-height: 1;
  color: transparent;
  white-space: nowrap;
  user-select: none;
}

[data-ui-type="Contents_Login_Sns"] .c-sns__button-toggle {
  position: absolute;
  top: 6px;
  right: 0;
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Sns"] .c-sns__button-toggle {
    top: 8px;
  }
}

[data-ui-type="Contents_Login_Sns"] .c-sns__button-toggle:before {
  width: 8px;
  height: 8px;
  border: 1px solid #333;
  border-width: 1px 1px 0 0;
  transform: rotate(135deg);
  content: "";
  display: block;
  margin: 0 auto 4px;
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] {
  margin-top: 14px;
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns {
  overflow: visible;
  height: auto;
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns {
    width: auto;
  }
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns__list {
  margin-left: -24px;
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns__list {
    display: flex;
    justify-content: space-between;
    margin-left: -15px;
  }
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns__item {
  position: relative;
  margin: 16px 0 0 24px;
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns__item {
    margin-left: 15px;
  }
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns__link {
  width: 40px;
  height: 40px;
  background: none;
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns__link {
    width: 44px;
    height: 44px;
  }
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns__link img {
  width: 100%;
  height: 100%;
  font-size: 10px;
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__list {
  margin-left: -20px;
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__item {
  margin-left: 20px;
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__link--phone {
  width: 44px;
  height: 44px;
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__link--phone img {
  content: url(/assets/img/svg/member/sns-phone-ios.svg);
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__link--kakao {
  width: 44px;
  height: 44px;
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__link--kakao img {
  content: url(/assets/img/svg/member/sns-kakao-ios.svg);
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__link--tid {
  width: 44px;
  height: 44px;
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__link--tid img {
  content: url(/assets/img/svg/member/sns-tid-ios.svg);
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__link--naver {
  width: 44px;
  height: 44px;
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__link--naver img {
  content: url(/assets/img/svg/member/sns-naver-ios.svg);
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__link--payco {
  width: 44px;
  height: 44px;
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__link--payco img {
  content: url(/assets/img/svg/member/sns-payco-ios.svg);
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__link--apple {
  width: 44px;
  height: 44px;
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-sns--ios .c-sns__link--apple img {
  content: url(/assets/img/svg/member/sns-apple-ios.svg);
}

.login-wrapper [data-ui-type="Contents_Login_Sns"] .c-bubble {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 18px;
  box-sizing: content-box;
  padding-bottom: 4px;
  background: url(/assets/img/svg/member/bg-bubble.svg) no-repeat;
  background-size: auto 100%;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
  font-weight: 400;
  font-size: 11px;
  color: #ff0038;
  line-height: 20px;
  text-align: center;
  vertical-align: top;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 7px);
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-ui-type="Contents_Login_Sns"] .c-bubble {
    width: 63px;
    padding-bottom: 5px;
  }
}

[data-ui-type="Contents_Login_Util"] {
  text-align: center;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Util"] {
    min-height: 38px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

[data-ui-type="Contents_Login_Util"] .c-util {
  display: inline-flex;
  vertical-align: top;
}

[data-ui-type="Contents_Login_Util"] .c-util__item {
  display: flex;
  align-items: center;
}

[data-ui-type="Contents_Login_Util"] .c-util__item:not(:first-child) {
  margin-left: 6px;
}

[data-ui-type="Contents_Login_Util"] .c-util__item:not(:first-child):before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: #ddd;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Util"] .c-util__item:not(:first-child) {
    margin-left: 10px;
  }
  [data-ui-type="Contents_Login_Util"] .c-util__item:not(:first-child):before {
    margin-right: 10px;
  }
}

[data-ui-type="Contents_Login_Util"] .c-util__item .c-iconinfo {
  width: 18px;
  height: 18px;
  margin-right: -2px;
}

[data-ui-type="Contents_Login_Util"] .c-util__item .c-infotip__layer {
  margin-top: 9px;
  padding: 14px 16px;
}

[data-ui-type="Contents_Login_Util"] .c-util__link {
  display: block;
  font-size: 14px;
  color: #000;
  line-height: 17px;
}

[data-ui-type="Contents_Login_Util"] .c-nonmember {
  position: relative;
}

[data-ui-type="Contents_Login_Util"] .c-nonmember__title {
  font-size: 16px;
  color: #111;
  line-height: 23px;
}

[data-ui-type="Contents_Login_Util"] .c-nonmember__desc {
  margin-top: 13px;
  font-size: 15px;
  color: #949494;
  line-height: 22px;
}

[data-ui-type="Contents_Login_Util"] .c-nonmember__list {
  display: flex;
  flex-wrap: wrap;
  margin: 6px 0 0 -10px;
  white-space: normal;
}

[data-ui-type="Contents_Login_Util"] .c-nonmember__item {
  width: 50%;
  padding: 10px 0 0 10px;
}

[data-ui-type="Contents_Login_Util"] .c-nonmember__link {
  display: block;
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #111;
  line-height: 1;
  text-align: center;
}

[data-ui-type="Contents_Login_Util"] .c-nonmember .c-tooltip-wrap {
  position: static;
  text-align: left;
}

[data-ui-type="Contents_Login_Util"] .c-nonmember .c-tooltip {
  left: 0;
  width: 386px;
  margin-top: 10px;
  padding: 16px 20px 20px;
  box-shadow: 0px 6px 20px -8px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.36);
}

[data-ui-type="Contents_Login_Util"] .c-nonmember .c-tooltip:before {
  display: none;
}

[data-ui-type="Contents_Login_Util"] .c-nonmember .c-tooltip__button-close {
  top: 22px;
  right: 22px;
  width: 12px;
  height: 12px;
}

[data-ui-type="Contents_Login_Util"] .c-nonmember .c-tooltip__button-close:before, [data-ui-type="Contents_Login_Util"] .c-nonmember .c-tooltip__button-close:after {
  background: #111;
}

[data-ui-type="Contents_Login_Util"] .c-bridge {
  margin-top: 20px;
  text-align: center;
}

[data-ui-type="Contents_Login_Util"] .c-bridge__button {
  display: inline-block;
  min-width: 162px;
  height: 48px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  color: #111;
  line-height: 46px;
  vertical-align: top;
}

[data-ui-type="Contents_Login_Nudge"] {
  padding: 23px 14px 7px;
}

[data-ui-type="Contents_Login_Nudge"] .c-info__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 33px;
  color: #111;
  text-align: center;
}

[data-ui-type="Contents_Login_Nudge"] .c-info__user-name:before {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-position: -40px -144px;
  background-size: 234px 198px;
  display: inline-block;
  margin: -1px 8px 1px 0;
  vertical-align: middle;
  content: "";
}

[data-ui-type="Contents_Login_Nudge"] .c-info__user-name--11st:before {
  width: 24px;
  height: 24px;
  background-position: -86.15385px -172.36364px;
  background-size: 280px 237px;
}

[data-ui-type="Contents_Login_Nudge"] .c-info__user-name--kakao:before {
  width: 24px;
  height: 24px;
  background-position: -186.66667px -172.36364px;
  background-size: 280px 237px;
}

[data-ui-type="Contents_Login_Nudge"] .c-info__user-name--tid:before {
  width: 24px;
  height: 24px;
  background-position: -224.95726px -71.81818px;
  background-size: 280px 237px;
}

[data-ui-type="Contents_Login_Nudge"] .c-info__user-name--naver:before {
  width: 24px;
  height: 24px;
  background-position: -224.95726px -4.78788px;
  background-size: 280px 237px;
}

[data-ui-type="Contents_Login_Nudge"] .c-info__user-name--payco:before {
  width: 24px;
  height: 24px;
  background-position: -224.95726px -38.30303px;
  background-size: 280px 237px;
}

[data-ui-type="Contents_Login_Nudge"] .c-info__user-name--apple:before {
  width: 24px;
  height: 24px;
  background-position: -119.65812px -172.36364px;
  background-size: 280px 237px;
}

[data-ui-type="Contents_Login_Nudge"] .c-info__point {
  color: #ff0038;
}

[data-ui-type="Contents_Login_Nudge"] .c-buttons {
  margin-top: 46px;
}

[data-ui-type="Contents_Login_Nudge"] .c-buttons .c-button {
  position: relative;
  font-size: 15px;
}

[data-ui-type="Contents_Login_Nudge"] .c-buttons .c-bubble {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 9px;
  border: 1px solid rgba(11, 131, 230, 0.2);
  border-radius: 100px;
  background: rgba(11, 131, 230, 0.02);
  height: 25px;
  margin-bottom: 5px;
  background-size: auto 100%;
  font-weight: 700;
  font-size: 11px;
  color: #0b83e6;
  vertical-align: top;
  position: absolute;
  top: -18px;
  left: 10px;
  background-color: #fafdfe;
}

[data-ui-type="Contents_Login_Nudge"] .c-buttons .c-bubble:after {
  content: "";
  position: absolute;
  top: 100%;
  transform: rotate(180deg);
  left: 13px;
  width: 12px;
  height: 6px;
  background: url(/assets/img/svg/member/bg_bubble_blue_tail.svg) no-repeat;
  background-size: 100% 100%;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Nudge"] .c-buttons .c-bubble {
    height: 28px;
    padding: 0 11px;
    font-size: 12px;
    margin-bottom: 6px;
  }
  [data-ui-type="Contents_Login_Nudge"] .c-buttons .c-bubble:after {
    height: 7px;
  }
}

[data-ui-type="Contents_Login_Nudge"] .c-notice {
  margin-top: 30px;
  font-size: 13px;
  line-height: 20px;
}

[data-ui-type="Contents_Login_Nudge"] .c-notice__desc {
  color: #333;
}

[data-ui-type="Contents_Login_Nudge"] .c-notice__emphasis {
  font-weight: 700;
  color: #333;
}

[data-ui-type="Contents_Login_Nudge"] .c-notice__detail {
  display: inline-block;
  position: relative;
  margin-top: 12px;
  border-bottom: 1px solid #333;
  color: #111;
  vertical-align: top;
  text-decoration: none;
}

[data-ui-type="Contents_Login_Nudge"] .c-notice__detail:after {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 5px;
  height: 9.16667px;
  background-position: -180px -71.66667px;
  background-size: 195px 165px;
  position: absolute;
  top: 50%;
  right: -9px;
  transform: translateY(-50%);
  content: "";
}

[data-ui-type="Contents_Login_Nudge"] + .c-dialog__close {
  top: -2px;
  right: -6px;
}

[data-ui-type="Contents_Login_Methods"] {
  padding: 40px 24px 24px;
}

[data-ui-type="Contents_Login_Methods"] .c-headline__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #000;
  text-align: center;
}

[data-ui-type="Contents_Login_Methods"] .c-buttons {
  display: block;
  margin-top: 24px;
}

[data-ui-type="Contents_Login_Methods"] .c-buttons .c-button {
  height: 44px;
  font-size: 16px;
}

[data-ui-type="Contents_Login_Methods"] .c-buttons .c-button:not(:first-child) {
  margin: 12px 0 0 0;
}

[data-ui-type="Contents_Login_Methods"] .c-buttons .c-button--normal {
  border-color: #ccc;
}

[data-ui-type="Contents_Login_Methods"] .c-buttons .c-button--kakao {
  border-color: #fae100;
  background-color: #fae100;
  color: #381e1f;
}

[data-ui-type="Contents_Login_Remember"]:last-child {
  padding-bottom: 14px;
}

[data-ui-type="Contents_Login_Remember"] .c-util {
  display: flex;
  position: relative;
  margin-top: 12px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Remember"] .c-util {
    margin-top: 20px;
  }
}

[data-ui-type="Contents_Login_Remember"] .c-util__item {
  font-size: 0;
}

[data-ui-type="Contents_Login_Remember"] .c-util__item .c-checkbox + label {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  color: #000;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Remember"] .c-util__item .c-checkbox + label {
    color: #111;
  }
}

[data-ui-type="Contents_Login_Remember"] .c-util__item .c-iconinfo--help {
  width: 20px;
  height: 22px;
  vertical-align: top;
}

[data-ui-type="Contents_Login_Remember"] .c-util__link {
  display: inline-block;
  position: relative;
  vertical-align: top;
  font-size: 14px;
  line-height: 22px;
  color: #333;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Remember"] .c-util__link {
    font-size: 13px;
    color: #111;
  }
}

[data-ui-type="Contents_Login_Remember"] .c-util__link:not(:first-child) {
  margin-left: 24px;
}

[data-ui-type="Contents_Login_Remember"] .c-util__link:not(:first-child):before {
  content: "";
  position: absolute;
  top: 50%;
  left: -12px;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background: #ccc;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Login_Remember"] .c-util__link:not(:first-child) {
    margin-left: 17px;
  }
  [data-ui-type="Contents_Login_Remember"] .c-util__link:not(:first-child):before {
    left: -9px;
    margin-top: -6px;
  }
}

[data-ui-type="Contents_Login_Remember"] .c-tooltip {
  left: -30px;
  margin-top: 12px;
}

[data-ui-type="Contents_Login_Remember"] .c-tooltip:before {
  left: 35px;
}

[data-ui-type="Contents_Login_Remember"] .c-passkey {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  margin-top: 24px;
  padding: 0 16px;
  border-radius: 4px;
  background: #f8f8f8;
}

[data-ui-type="Contents_Login_Remember"] .c-passkey__item {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

[data-ui-type="Contents_Login_Remember"] .c-passkey__text {
  padding-bottom: 1px;
  font-size: 13px;
  color: #333;
}

[data-ui-type="Contents_Login_Remember"] .c-passkey__button {
  display: inline-flex;
  align-items: center;
  height: 30px;
  padding: 0 12px 1px;
  border-radius: 360px;
  background: #ff0038;
  font-size: 14px;
  color: #fff;
  vertical-align: top;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Oauth_Notice"] {
    max-width: 328px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Oauth_Notice"] .c-notice {
    margin-top: 80px;
    font-size: 14px;
  }
}

[data-ui-type="Contents_Oauth_Notice"] .c-notice__title {
  margin-bottom: 8px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Oauth_Notice"] .c-notice__title {
    margin-bottom: 14px;
    line-height: 20px;
  }
}

[data-ui-type="Contents_Oauth_Notice"] .c-notice__desc {
  position: relative;
  color: #949494;
  line-height: 16px;
  word-break: break-all;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Oauth_Notice"] .c-notice__desc {
    line-height: 20px;
    letter-spacing: -0.01em;
  }
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Oauth_Notice"] .c-notice__desc:not(:last-child) {
    margin-bottom: 10px;
  }
}

[data-ui-type="Contents_Oauth_Notice"] .c-notice__desc:before {
  content: "";
  position: absolute;
  top: 6px;
  right: calc(100% + 4px);
  width: 2px;
  height: 2px;
  background-color: #666;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Oauth_Notice"] .c-notice__desc:before {
    top: 10px;
  }
}

[data-ui-type="Contents_Oauth_Notice"] .c-notice__desc em {
  color: #ff0038;
}

[data-ui-type="Contents_Passkey_Info"] {
  padding: 16px 24px 15px;
}

[data-ui-type="Contents_Passkey_Info"] .c-guide {
  display: flex;
}

[data-ui-type="Contents_Passkey_Info"] .c-guide__item {
  position: relative;
  flex-shrink: 0;
  color: #111;
}

[data-ui-type="Contents_Passkey_Info"] .c-guide__item:not(:first-child) {
  padding-left: 72px;
}

[data-ui-type="Contents_Passkey_Info"] .c-guide__item:not(:first-child):before {
  content: "";
  position: absolute;
  top: 50%;
  left: 22px;
  width: 19px;
  height: 19px;
  margin-top: 9px;
  border: solid #949494;
  border-width: 3px 3px 0 0;
  border-radius: 0 1px 0 0;
  transform: rotate(45deg);
}

[data-ui-type="Contents_Passkey_Info"] .c-guide__step {
  font-size: 19px;
  font-weight: 900;
  line-height: 22px;
}

[data-ui-type="Contents_Passkey_Info"] .c-guide__info {
  margin-top: 16px;
}

[data-ui-type="Contents_Passkey_Info"] .c-guide__info + .c-guide__info {
  margin-top: 20px;
}

[data-ui-type="Contents_Passkey_Info"] .c-guide__info-image {
  width: 382px;
  vertical-align: top;
}

[data-ui-type="Contents_Prompt_Message"] {
  padding: 18px 0 0;
  text-align: center;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Prompt_Message"] {
    padding: 26px 14px 0;
  }
}

[data-ui-type="Contents_Prompt_Message"] .c-info__title {
  padding: 0 14px;
  line-height: 14px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Prompt_Message"] .c-info__title {
    padding: 0;
  }
}

[data-ui-type="Contents_Prompt_Message"] .c-info__logo {
  display: inline-block;
  margin: -3px 3px 0 0;
  vertical-align: middle;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Prompt_Message"] .c-info__logo {
    margin-top: -6px;
  }
}

[data-ui-type="Contents_Prompt_Message"] .c-info__user-info {
  display: inline;
  font-size: 17px;
  font-weight: 700;
  color: #111;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Prompt_Message"] .c-info__user-info {
    font-size: 20px;
    line-height: 28px;
  }
}

[data-ui-type="Contents_Prompt_Message"] .c-info__user-name {
  margin-right: -2px;
}

[data-ui-type="Contents_Prompt_Message"] .c-info__desc {
  margin-top: 24px;
  font-size: 16px;
  color: #333;
  word-break: keep-all;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Prompt_Message"] .c-info__desc {
    margin-top: 30px;
    font-size: 16px;
  }
}

[data-ui-type="Contents_Prompt_Message"] .c-buttons {
  margin-top: 24px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Prompt_Message"] .c-buttons {
    margin-top: 30px;
  }
}

[data-ui-type="Contents_Prompt_Message"] .c-notice {
  margin-top: 24px;
}

[data-ui-type="Contents_Prompt_Message"] .c-notice__desc {
  margin-bottom: 9px;
  line-height: normal;
}

[data-ui-type="Contents_Prompt_Message"] .c-notice__detail {
  position: relative;
  margin-left: -9px;
  border-bottom: 1px solid #111;
  font-size: 13px;
  color: #111;
  letter-spacing: -0.5px;
  text-decoration: none;
}

[data-ui-type="Contents_Prompt_Message"] .c-notice__detail:after {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 5px;
  height: 9.16667px;
  background-position: -180px -71.66667px;
  background-size: 195px 165px;
  position: absolute;
  top: 4px;
  right: -9px;
  content: "";
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Prompt_Message"] .c-notice {
    margin-top: 30px;
  }
  [data-ui-type="Contents_Prompt_Message"] .c-notice__desc {
    margin-bottom: 5px;
    line-height: 20px;
  }
  [data-ui-type="Contents_Prompt_Message"] .c-notice__desc br {
    display: none;
  }
  [data-ui-type="Contents_Prompt_Message"] .c-notice__detail {
    line-height: 20px;
  }
  [data-ui-type="Contents_Prompt_Message"] .c-notice__detail:after {
    top: 6px;
  }
}

[data-ui-type="Contents_Prompt_Message"] + .c-dialog__close {
  display: block;
  top: -3px;
  right: -16px;
}

[data-ui-type="Contents_Prompt_Message"] + .c-dialog__close:before, [data-ui-type="Contents_Prompt_Message"] + .c-dialog__close:after {
  width: 2px;
  height: 34px;
  background: #333;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Prompt_Message"] + .c-dialog__close {
    top: -2px;
    right: -6px;
  }
  [data-ui-type="Contents_Prompt_Message"] + .c-dialog__close:before, [data-ui-type="Contents_Prompt_Message"] + .c-dialog__close:after {
    height: 50px;
    background: #111;
  }
}

[data-ui-type="Contents_Prompt_Message"] .point {
  font-weight: bold;
  color: #ff0038;
}

[data-ui-type="Contents_Quick_Login"] {
  padding-top: 30px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Quick_Login"] {
    padding-bottom: 13px;
  }
}

[data-ui-type="Contents_Quick_Login"]:not(:last-child) {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

[data-ui-type="Contents_Quick_Login"] .c-headline {
  position: relative;
  margin-bottom: 16px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Quick_Login"] .c-headline {
    margin-bottom: 24px;
  }
}

[data-ui-type="Contents_Quick_Login"] .c-headline:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background: #eee;
}

[data-ui-type="Contents_Quick_Login"] .c-headline__desc {
  display: inline-block;
  position: relative;
  padding: 0 5px;
  background: #fff;
  font-size: 13px;
  color: #949494;
  line-height: 17px;
  vertical-align: top;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Quick_Login"] .c-headline__desc {
    font-size: 14px;
    line-height: 21px;
  }
}

[data-ui-type="Contents_Quick_Login"] .c-box {
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
}

[data-ui-type="Contents_Quick_Login"] .c-box__item {
  height: 52px;
  border: 0;
}

[data-ui-type="Contents_Quick_Login"] .c-box__item:not(:only-child):not(:first-child) {
  border-top: 1px solid #eee;
}

[data-ui-type="Contents_Quick_Login"] .c-box__item--active .c-box__button-id:before {
  width: 14px;
  height: 14px;
  background-position: -216px -26px;
  background-size: 234px 198px;
}

[data-ui-type="Contents_Quick_Login"] .c-box__button-id {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  height: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #111;
  text-align: left;
}

[data-ui-type="Contents_Quick_Login"] .c-box__button-id:before {
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 14px;
  height: 14px;
  background-position: -216px -4px;
  background-size: 234px 198px;
  content: "";
  flex-shrink: 0;
  margin: 0 10px 0 16px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Quick_Login"] .c-box__button-id {
    padding-bottom: 2px;
    font-size: 15px;
  }
  [data-ui-type="Contents_Quick_Login"] .c-box__button-id:before {
    margin-bottom: -2px;
  }
}

[data-ui-type="Contents_Quick_Login"] .c-box__button-delete {
  padding: 0 19px;
}

[data-ui-type="Contents_Quick_Login"] .c-bubble {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 18px;
  border: 1px solid rgba(255, 0, 56, 0.2);
  border-radius: 20px;
  background: rgba(255, 0, 56, 0.02);
  font-weight: 400;
  font-size: 11px;
  color: #ff0038;
  line-height: 20px;
  text-align: center;
  vertical-align: top;
  flex-shrink: 0;
  position: relative;
  transform: translateZ(0);
  margin-left: 6px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Quick_Login"] .c-bubble {
    width: 63px;
    padding-bottom: 1px;
  }
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Quick_Login"] .c-bubble {
    margin-bottom: -2px;
  }
}

[data-ui-type="Contents_Quick_Login"] .c-bridge {
  margin-top: 30px;
  text-align: center;
}

[data-ui-type="Contents_Quick_Login"] .c-bridge__link {
  display: inline-block;
  padding-left: 3px;
  vertical-align: top;
  font-weight: 700;
  font-size: 14px;
  color: #333;
  line-height: 17px;
}

[data-ui-type="Contents_Quick_Login"] .c-bridge__link:after {
  content: '';
  width: 8px;
  height: 8px;
  border: 1px solid currentColor;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
  content: "";
  display: inline-block;
  margin: -2px 0 1px 1px;
  vertical-align: middle;
}

.login-wrapper [data-ui-type="Cap_Login_Logo"] + [data-ui-type="Contents_Quick_Login"] {
  padding-top: 0;
}

.login-wrapper [data-ui-type="Cap_Login_Logo"] + [data-ui-type="Contents_Quick_Login"] .c-headline:before {
  display: none;
}

.login-wrapper [data-ui-type="Cap_Login_Logo"] + [data-ui-type="Contents_Quick_Login"] .c-headline__desc {
  background: transparent;
}

.login-wrapper [data-ui-type="Cap_Login_Mobile"] + [data-ui-type="Contents_Quick_Login"] {
  padding-top: 0;
}

@media screen and (min-width: 768px) {
  .login-wrapper [data-ui-type="Cap_Login_Mobile"] + [data-ui-type="Contents_Quick_Login"] .c-headline:before {
    display: none;
  }
  .login-wrapper [data-ui-type="Cap_Login_Mobile"] + [data-ui-type="Contents_Quick_Login"] .c-headline__desc {
    background: transparent;
  }
}

[data-ui-type="Contents_Register_Info"] {
  position: relative;
  margin: 47px 0 49px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Register_Info"] {
    margin: 27px 14px 56px;
  }
}

[data-ui-type="Contents_Register_Info"] .c-info {
  text-align: center;
}

[data-ui-type="Contents_Register_Info"] .c-info__desc {
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
}

[data-ui-type="Contents_Register_Info"] .c-info__desc-primary {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #333;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Register_Info"] .c-info__desc-primary br {
    display: none;
  }
}

[data-ui-type="Contents_Register_Info"] .c-info__desc-primary .point {
  color: #ff0038;
}

[data-ui-type="Contents_Register_Info"] .c-info-img {
  margin-top: 4px;
  padding: 0 12px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Register_Info"] .c-info-img {
    margin-top: 2px;
  }
}

[data-ui-type="Contents_Register_Info"] .c-info-img__content {
  position: relative;
  max-width: 272px;
  margin: 0 auto;
  padding-top: 78.67647%;
  background: url("/assets/img/member/img_register_info.png") no-repeat center top;
  background-size: 100%;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Register_Info"] .c-info-img__content {
    max-width: 300px;
    padding-top: 250px;
  }
}

[data-ui-type="Contents_Register_Info"] .c-info-img__text-id {
  overflow: hidden;
  position: absolute;
  top: 52.8%;
  left: 54px;
  right: 54px;
  font-weight: bold;
  font-size: 20px;
  color: #000;
  line-height: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Register_Info"] .c-info-img__text-id {
    top: 126px;
    font-size: 22px;
    font-weight: 900;
  }
}

[data-ui-type="Contents_Register_Info"] .c-notice {
  margin: 8px 3px 0 0;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Register_Info"] .c-notice {
    margin-top: 26px;
  }
}

[data-ui-type="Contents_Register_Info"] .c-notice__title {
  margin-bottom: 4px;
  color: #111;
  line-height: 20px;
}

[data-ui-type="Contents_Register_Info"] .c-notice__desc {
  margin-bottom: 0;
  color: #666;
}

[data-ui-type="Contents_Register_Info"] .c-notice__desc br {
  display: none;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Register_Info"] .c-notice__desc br {
    display: inline;
  }
}

[data-ui-type="Contents_Register_Info"] .c-buttons {
  margin-top: 8px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Register_Info"] .c-buttons {
    margin-top: 26px;
  }
}

[data-ui-type="Contents_Register_Info"] .c-close-today {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 12px;
  font-size: 14px;
  color: #333;
  line-height: 17px;
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Register_Info"] .c-close-today {
    margin-top: 18px;
  }
}

[data-ui-type="Contents_Register_Info"] .c-close-today:before {
  content: "";
  display: inline-block;
  background: url(/assets/img/svg/sprites/sp_member_202418_150536.svg) no-repeat;
  width: 14px;
  height: 14px;
  background-position: -4px -180px;
  background-size: 234px 198px;
  margin: -1px 4px 1px 0;
  vertical-align: middle;
}

[data-ui-type="Contents_Result"] {
  max-width: 350px;
  margin: 0 auto;
}

[data-ui-type="Contents_Result"] .c-headline__title {
  padding-top: 33px;
  font-weight: bold;
  font-size: 22px;
  color: #333;
  line-height: 28px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Result"] .c-headline__title {
    padding-top: 40px;
    font-size: 20px;
  }
}

[data-ui-type="Contents_Result"] .c-headline__title--logo {
  padding-top: 43px;
  font-size: 21px;
  line-height: 26px;
  color: #111;
}

[data-ui-type="Contents_Result"] .c-headline__title--logo:before {
  content: "";
  display: block;
  margin-bottom: 32px;
  width: 56px;
  height: 24px;
  background-image: url("/assets/img/svg/logo/11st.svg");
  background-size: 56px 24px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Result"] .c-headline__title--logo:before {
    display: none;
  }
}

[data-ui-type="Contents_Result"] .c-headline__desc {
  margin-top: 8px;
  font-size: 15px;
  line-height: 21px;
  color: #666;
}

@media screen and (min-width: 768px) {
  [data-ui-type="Contents_Result"] .c-headline__desc {
    font-size: 14px;
  }
}

[data-ui-type="Contents_Result"] .c-box {
  overflow: visible;
  position: relative;
  margin-top: 32px;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

[data-ui-type="Contents_Result"] .c-box__time {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
}

[data-ui-type="Contents_Result"] .c-list {
  font-size: 13px;
  line-height: 18px;
}

[data-ui-type="Contents_Result"] .c-list dt {
  float: left;
  width: 63px;
}

[data-ui-type="Contents_Result"] .c-list dt:not(:first-of-type) {
  margin-top: 8px;
}

[data-ui-type="Contents_Result"] .c-list dd {
  overflow: hidden;
  color: #333;
}

[data-ui-type="Contents_Result"] .c-list dd:not(:first-of-type) {
  margin-top: 8px;
}

[data-ui-type="FlatButton_Login"] {
  margin-top: 53px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  [data-ui-type="FlatButton_Login"] {
    margin-top: 35px;
  }
}

[data-ui-type="FlatButton_Login"] .c-join__link {
  display: inline-block;
  padding-left: 3px;
  vertical-align: top;
  font-weight: 700;
  font-size: 16px;
  color: #333;
  line-height: 20px;
}

@media screen and (min-width: 768px) {
  [data-ui-type="FlatButton_Login"] .c-join__link {
    font-size: 14px;
  }
}

[data-ui-type="FlatButton_Login"] .c-join__link:after {
  content: '';
  width: 8px;
  height: 8px;
  border: 1px solid currentColor;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
  content: "";
  display: inline-block;
  margin: -2px 0 1px 1px;
  vertical-align: middle;
}

[data-ui-type="FlatButton_Login"] .c-nonmember {
  position: relative;
  margin-top: 18px;
}

[data-ui-type="FlatButton_Login"] .c-nonmember__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 162px;
  height: 48px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #111;
  vertical-align: top;
}

[data-ui-type="FlatButton_Login"] .c-nonmember__desc {
  font-size: 14px;
  color: #666;
  text-align: center;
  line-height: 20px;
}

[data-ui-type="FlatButton_Login"] .c-nonmember__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
}

[data-ui-type="FlatButton_Login"] .c-nonmember__item {
  margin: 2px;
}

[data-ui-type="FlatButton_Login"] .c-headline__desc {
  margin: 39px 0 10px;
  color: #999;
  font-size: 13px;
  line-height: 19px;
}
