// 간편 로그인 2단계 인증 > 로그인 상태 유지 하단 공통 스타일
%util-login {
    .c-util {
        display: flex;
        position: relative;
        @include mq-768 {
            margin-bottom: 54px;
        }
        &__item {
            @include clearfix;
            flex: 1;
            .c-checkbox {
                & + label {
                    display: inline-block;
                    vertical-align: top;
                    font-size: 13px;
                    color: $color-gray-01;
                    @include mq-768 {
                        margin-top: 12px;
                        margin-left: 1px;
                        line-height: 21px;
                    }
                }
            }
        }
        &__link-arrow {
            float: right;
            margin-top: 12px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 14px;
            color: $color-gray-03;
            line-height: 17px;
            @include mq-768 {
                margin-bottom: 0;
                font-size: 13px;
                color: $color-gray-02;
                line-height: 22px;
            }
            &:after {
                display: inline-block;
                margin: 0 2px;
                vertical-align: 1px;
                @include ico-arrow-right(8px, currentColor);
            }
        }
    }

    // only PC
    .c-tooltip {
        @include blue-bubble($position-x: left, $position-y: bottom);
        position: absolute;
        width: auto;
        top: 4px;
        left: -9px;
        letter-spacing: 0;
        line-height: inherit;
        box-shadow: none;
        &:before {
            display: none;
        }
        &__desc {
            font-weight: 400;
            font-size: 12px;
            color: $color-11st-blue;

            em {
                font-weight: 700;
            }
        }
    }

    .c-notice {
        margin-top: 20px;
        @include mq-768 {
            margin-top: 52px;
        }
        &__desc {
            &,
            &:not(:last-child) {
                margin-bottom: 0;
            }
            .tel {
                color: inherit;
                text-decoration: none;
            }
        }
    }

    .c-bridge {
        margin: 30px 0;
        &__link-arrow {
            font-weight: bold;
            font-size: 13px;
            color: $color-gray-03;
            line-height: 16px;
            &:after {
                display: inline-block;
                margin: 0 2px;
                vertical-align: 1px;
                @include ico-arrow-right(8px, currentColor);
            }
        }
    }
}
