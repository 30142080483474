.c-notice {
    font-size: 13px;

    &__title {
        display: block;
        margin-bottom: 6px;
        font-weight: bold;
        color: $color-gray-03;
        line-height: 16px;
        @include mq-768 {
            line-height: 19px;
        }
    }
    &__desc {
        line-height: 18px;
        color: $color-gray-06;

        &:not(:last-child) {
            margin-bottom: 8px;
            @include mq-768 {
                margin-bottom: 4px;
            }
        }

        em {
            color: $color-gray-02;
        }

        .number {
            font-weight: bold;
        }
    }
    a, // 기존 상용 대응
    &__link {
        color: $color-11st-blue;
        line-height: 18px;
        text-decoration: underline;
    }
}
