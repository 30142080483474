@mixin mq-768($over: true) {
    @if ($over == true) {
        @media screen and (min-width: 768px) {
            @content;
        }
    } @else {
        @media screen and (min-width: 769px) {
            @content;
        }
    }
}
