@import "../../svg/sp_member";

.b-layer__modal {
    display: none;
    overflow: hidden;
    position: fixed;
    width: 100%;
    max-width: 1280px;
    padding-top: 56px;
    background-color: $color-gray-14;
    z-index: 103;
    box-sizing: border-box;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 0 -2px 5px 2px rgba(0, 0, 0, 0.15);
    outline: none;

    &-title {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 56px;
        padding: 0 16px;
        color: $color-gray-01;
        font-size: 19px;
        line-height: 58px;
        text-align: center;
        box-sizing: border-box;

        &.b-layer__modal-left {
            text-align: left;
        }
    }

    &-content {
        overflow: hidden;
        max-height: calc(100vh - 257px);
        padding: 16px;
        @media screen and (orientation: landscape) {
            max-height: 100%;
        }
    }

    &-close {
        position: absolute;
        top: 8px;
        right: 9px;
        width: 44px;
        height: 44px;
        background: none;
        border: none;
        color: transparent;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 3px;
            height: 50px;
            margin: -27px 0 0 -1px;
            background: $color-gray-03;
        }

        &:before {
            transform: scale(0.5) rotate(45deg);
        }

        &:after {
            transform: scale(0.5) rotate(-45deg);
        }
    }
    @at-root .join-wrapper & {
        @include mq-768 {
            max-width: 400px;
        }
        &-content {
            overflow-y: auto;
            padding: 0;
            max-height: calc(100vh - 125px);
        }

        // 이메일, 통신사 옵션
        .c-select {
            &__button {
                width: 100%;
                padding: 13px 16px;
                font-size: 16px;
                color: $color-gray-03;
                line-height: 22px;
                text-align: left;
                &[aria-selected="true"] {
                    background-color: $color-gray-13;
                    font-weight: 600;
                }
                &:hover,
                &:focus {
                    background-color: $color-gray-13;
                }
            }
        }

        // 이용약관동의 팝업
        .c-terms {
            &__box {
                display: flex;
                align-items: center;
                height: 56px;
                margin: 10px 20px 0;
                border-radius: 4px;
                background-color: $color-gray-13;
                font-weight: 700;
                label {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding-left: 50px;
                    &:before {
                        top: 50%;
                        left: 20px;
                        margin-top: -11px;
                    }
                    &:after {
                        top: 50%;
                        left: 28px;
                        margin-top: -7px;
                    }
                }
            }
            &__list {
                padding: 0 20px 24px;
            }
            &__item {
                margin-top: 20px;
            }
            &__details {
                display: none;
                margin-top: 10px;
                padding: 0 26px;
            }
            &__text {
                margin-top: 11px;
                font-size: 13px;
                color: $color-gray-06;
            }
            &__button {
                .c-button {
                    width: 100%;
                    height: 52px;
                    font-size: 17px;
                    font-weight: 700;
                    line-height: 48px;
                }
            }
        }
        .c-agree {
            position: relative;
            display: flex;
            padding-left: 30px;
            line-height: 24px;
            &__input {
                position: absolute;
                top: 50%;
                left: 0;
                width: 24px;
                height: 24px;
                margin-top: -12px;
                opacity: 0;
                &:checked + label:before {
                    @include get-sprite-position-member("ic-check-on");
                }
            }
            &__label {
                font-size: 15px;
                color: $color-gray-02;
                &:before {
                    @include get-sprite-member("ic-check-off");
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 6px;
                    margin-top: -7px;
                }
            }
            &__link {
                flex-shrink: 0;
                width: 24px;
                height: 24px;
                margin-left: auto;
                &:after {
                    @include get-sprite-member("ic-arrow-right");
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 6px;
                    transform: translateY(-50%);
                }
            }
            &__button {
                width: 24px;
                height: 24px;
                margin-left: auto;
                &:after {
                    @include get-sprite-member("ic-arrow");
                    content: "";
                    display: block;
                    margin: 0 auto;
                    transform: rotate(-180deg);
                }
            }
            &--open {
                .c-agree__button:after {
                    transform: none;
                }
                & + .c-terms__details {
                    display: block;
                }
            }
            &--sub {
                padding-right: 0;
                &:not(:first-child) {
                    margin-top: 4px;
                }
                .c-agree__label {
                    color: $color-gray-04;
                }
            }
        }
    }

    // PC구간에선 모달형으로 변경
    &--responsive {
        padding-top: 0;
        @include mq-768 {
            width: 340px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            &.slideOutBottom {
                animation-name: fadeOut;
            }
            &.slideInBottom {
                animation-name: fadeIn;
            }
            &.modal-bottom {
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
