[data-ui-type="Cap_Box_Auth"] {
    padding: 32px 0;
    @include mq-768 {
        max-width: 350px;
        margin: 0 auto;
        padding-top: 40px;
    }
    @at-root .join-wrapper & {
        max-width: none;
        padding: 24px 0 0;
        .c-option {
            margin: 0;
            padding: 9px 0;
        }
    }

    .c-headline {
        color: $color-gray-02;
        line-height: 26px;

        &__title {
            font-weight: bold;
            font-size: 20px;
        }
        &__desc {
            margin-top: 4px;
            font-size: 16px;
        }
    }

    .c-account {
        display: flex;
        margin-bottom: 31px;
        &__type {
            @include get-sprite-member("sns_id_11st", 24);
            overflow: hidden;
            margin-right: 10px;
            font-size: 1px;
            color: transparent;
        }
        &__id {
            font-size: 20px;
            font-weight: 700;
            color: $color-gray-02;
            line-height: 24px;
            @include mq-768 {
                font-weight: 900;
            }
        }
    }
    .c-option {
        display: flex;
        margin-top: 10px;

        &__item {
            &:not(:first-child) {
                margin-left: 33px;
            }
        }

        @include mq-down-360 {
            &__item {
                flex: 1;
                max-width: 37%;
                white-space: nowrap;
                &:not(:first-child) {
                    margin-left: 18px;
                }
            }
        }
    }

    .c-radio {
        & + label {
            font-size: 16px;
            color: $color-gray-02;
        }

        &:checked {
            & + label {
                font-weight: bold;
            }
        }
    }

    .c-tab {
        @include mq-768 {
            margin-bottom: 20px;
        }
    }
}
