[data-ui-type="Contents_Prompt_Message"] {
    // 모바일 퍼스트, mq-768 = PC
    padding: 18px 0 0;
    text-align: center;
    @include mq-768 {
        padding: 26px 14px 0;
    }

    .c-info {
        &__title {
            padding: 0 14px;
            line-height: 14px;

            @include mq-768 {
                padding: 0;
            }
        }

        &__logo {
            display: inline-block;
            margin: -3px 3px 0 0;
            vertical-align: middle;

            @include mq-768 {
                margin-top: -6px;
            }
        }

        &__user-info {
            display: inline;
            font-size: 17px;
            font-weight: 700;
            color: $color-gray-02;

            @include mq-768 {
                font-size: 20px;
                line-height: 28px;
            }
        }

        &__user-name {
            margin-right: -2px;
        }

        &__desc {
            margin-top: 24px;
            font-size: 16px;
            color: $color-gray-03;
            word-break: keep-all;

            @include mq-768 {
                margin-top: 30px;
                font-size: 16px;
            }
        }
    }

    .c-buttons {
        margin-top: 24px;

        @include mq-768 {
            margin-top: 30px;
        }
    }

    .c-notice {
        margin-top: 24px;

        &__desc {
            margin-bottom: 9px;
            line-height: normal;
        }

        &__detail {
            position: relative;
            margin-left: -9px;
            border-bottom: 1px solid $color-gray-02;
            font-size: 13px;
            color: $color-gray-02;
            letter-spacing: -0.5px;
            text-decoration: none;
            &:after {
                @include get-sprite-member("ic-arrow-right", 5);
                position: absolute;
                top: 4px;
                right: -9px;
                content: "";
            }
        }

        @include mq-768 {
            margin-top: 30px;

            &__desc {
                margin-bottom: 5px;
                line-height: 20px;

                br {
                    display: none;
                }
            }

            &__detail {
                line-height: 20px;

                &:after {
                    top: 6px;
                }
            }
        }
    }

    // 닫기 버튼
    & + .c-dialog__close {
        display: block;
        top: -3px;
        right: -16px;

        &:before,
        &:after {
            width: 2px;
            height: 34px;
            background: $color-gray-03;
        }

        @include mq-768 {
            top: -2px;
            right: -6px;

            &:before,
            &:after {
                height: 50px;
                background: $color-gray-02;
            }
        }
    }

    .point {
        font-weight: bold;
        color: $color-11st-red;
    }
}
