[data-ui-type="Contents_Login_Methods"] {
    padding: 40px 24px 24px;

    .c-headline {
        &__title {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: $color-gray-01;
            text-align: center;
        }
    }

    .c-buttons {
        display: block;
        margin-top: 24px;

        .c-button {
            height: 44px;
            font-size: 16px;

            &:not(:first-child) {
                margin: 12px 0 0 0;
            }
            &--normal {
                border-color: $color-gray-09;
            }
            &--kakao {
                border-color: #fae100;
                background-color: #fae100;
                color: #381e1f;
            }
        }
    }
}
