// 구버전과 현버전(.login-wrapper) 혼용
[data-ui-type="Contents_Login_Form"] {
    // 구버전 스타일 (auth 페이지)
    margin-top: 10px;
    @include mq-768 {
        max-width: 328px;
        margin: 0 auto;
    }

    .c-box {
        &__item {
            height: 55px;
            @include mq-768 {
                height: 57px;
            }
            &--active {
                z-index: 1;
                border-color: $color-11st-red;
                &:not(:first-child) {
                    box-shadow: 0 -1px 0 $color-11st-red, inset 0 0.2px 0 $color-gray-14;
                }
            }
            .c-input {
                outline: none;
                font-size: 16px;
                @include mq-768 {
                    font-size: 15px;
                }
                &::placeholder {
                    color: $color-gray-08;
                    @include mq-768 {
                        color: $color-gray-06;
                    }
                }
            }
        }
        &__button-delete,
        &__button-masking {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
        &__button-delete {
            @include mq-768 {
                padding-right: 24px;
            }
        }
        &__button-masking {
            height: 100%;
            padding: 0 13px;
            @include mq-768 {
                padding-right: 20px;
            }
            &:before {
                display: block;
                content: "";
            }

            &[aria-pressed="false"] {
                &:before {
                    @include get-sprite-member("ic-input-hide");
                }
            }
            &[aria-pressed="true"] {
                &:before {
                    @include get-sprite-member("ic-input-show");
                }
            }
        }
    }

    .c-error {
        &__message {
            margin: 16px 4px;
            font-size: 13px;
            line-height: 16px;
            color: $color-11st-red;
            word-break: keep-all;
        }
    }

    .c-buttons {
        margin-top: 12px;
        @include mq-768 {
            margin-top: 10px;
        }
        .c-button {
            height: 56px;
        }
    }

    // TODO: MPSR-146801 배포 후 제거 (Start)
    .c-util {
        display: flex;
        margin-top: 12px;
        @include mq-768 {
            margin-top: 8px;
        }

        &__item {
            font-size: 0;
            &:nth-child(2n) {
                flex: 1;
                text-align: right;
            }
            .c-checkbox {
                & + label {
                    display: inline-block;
                    vertical-align: top;
                    font-size: 14px;
                    @include mq-768 {
                        font-size: 13px;
                        color: $color-gray-02;
                    }
                }
            }
            .c-iconinfo--help {
                height: 22px;
                vertical-align: top;
            }
        }

        &__link {
            display: inline-block;
            position: relative;
            vertical-align: top;
            font-size: 14px;
            line-height: 22px;
            color: $color-gray-03;
            @include mq-768 {
                font-size: 13px;
                color: $color-gray-02;
            }

            &:not(:first-child) {
                margin-left: 24px;
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: -12px;
                    width: 1px;
                    height: 14px;
                    margin-top: -7px;
                    background: $color-gray-09;
                }
                @include mq-768 {
                    margin-left: 17px;
                    &:before {
                        left: -9px;
                        margin-top: -6px;
                    }
                }
            }
        }
    }
    // MPSR-146801 배포 후 제거 (End)

    .c-captcha {
        margin: 16px 0 20px;
        iframe {
            margin: 0 auto;
            border-radius: 4px;
        }

        &--error {
            iframe {
                border: 1px solid $color-11st-red;
            }
        }
    }

    // 로그인 renewal default, 신규 스타일은 하위에 선언
    @include login-new {
        margin-top: 20px;
        @include mq-768 {
            max-width: none;
            margin-top: 22px;
        }

        .c-box {
            &__item {
                height: 48px;

                &:not(.c-box__item--active) {
                    border-color: $color-gray-10;
                    &:first-child {
                        border-bottom-color: $color-gray-11;
                    }
                }

                .c-input {
                    &::placeholder {
                        font-size: 15px;
                        color: $color-gray-06;
                    }
                    @include mq-768 {
                        padding-bottom: 2px;
                    }
                }
            }

            &__button-delete {
                padding: 0 18px;
            }

            &__button-masking {
                padding: 0 15px;

                &[aria-pressed="false"] {
                    &:before {
                        @include get-sprite-position-member("ic-input-hide", 24);
                    }
                }
                &[aria-pressed="true"] {
                    &:before {
                        @include get-sprite-position-member("ic-input-show", 24);
                    }
                }
            }
        }

        .c-error {
            &__message {
                margin: 12px 0;
                text-align: center;
                @include mq-768 {
                    line-height: 19px;
                }
            }
        }

        .c-buttons {
            .c-button--submit {
                height: 52px;
                font-weight: 700;
                border-color: $color-11st-red;
                background: $color-11st-red;
                color: $color-gray-14;
                &[disabled] {
                    border-color: $color-gray-09;
                    background-color: $color-gray-09;
                }
            }
        }

        // TODO: MPSR-146801 배포 후 제거 (Start)
        .c-util {
            position: relative;
            @include mq-768 {
                margin-top: 12px;
            }
            &__item {
                .c-iconinfo--help {
                    width: 20px;
                }
            }
            .c-checkbox {
                & + label {
                    font-size: 13px;
                    color: $color-gray-01;
                }
            }
        }
        .c-tooltip {
            left: -30px;
            margin-top: 12px;
            &:before {
                left: 35px;
            }
        }
        // MPSR-146801 배포 후 제거 (End)

        .c-captcha {
            margin: 12px 0;
        }
    }

    // TODO: MPSR-146801 배포 후 제거 (Start)
    .c-passkey {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;
        margin-top: 24px;
        padding: 0 16px;
        border-radius: 4px;
        background: #f8f8f8;
        &__item {
            display: flex;
            align-items: center;
            flex-shrink: 0;
        }
        &__text {
            padding-bottom: 1px;
            font-size: 13px;
            color: $color-gray-03;
        }
        &__button {
            display: inline-flex;
            align-items: center;
            height: 30px;
            padding: 0 12px 1px;
            border-radius: 360px;
            background: $color-11st-red;
            font-size: 14px;
            color: $color-gray-14;
            vertical-align: top;
        }
    }
    // MPSR-146801 배포 후 제거 (End)
}
