// only pc
.c-tooltip-wrap {
    display: none;
    position: relative;

    &--show {
        display: block;
    }
}
.c-tooltip {
    position: absolute;
    top: 100%;
    left: -32px;
    z-index: 10;
    margin-top: 17px;
    border-radius: 4px;
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
    background-color: $color-gray-14;
    padding: 10px 62px 10px 14px;
    letter-spacing: -0.1px;
    line-height: 21px;
    white-space: nowrap;

    &:before {
        position: absolute;
        top: -6px;
        left: 37px;
        width: 11px;
        height: 11px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-width: 1px 1px 0 0;
        background-color: $color-gray-14;
        transform: rotate(-45deg);
        content: "";
    }

    &__desc {
        font-size: 14px;
        color: #090909;

        .point {
            color: #fc3646;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        font-size: 12px;
        color: rgba($color-gray-02, 0.8);

        &:after {
            @include ico-arrow-right(6px, currentColor);
            margin-left: 1px;
        }
    }

    &__button-close {
        @include btn-close-x(16px);
        position: absolute;
        top: 14px;
        right: 15px;
        width: 13px;
        height: 13px;
    }

    &--arrow {
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 328px;
        margin-top: 19px;
        padding: 23px;
        background: $color-gray-13;
        border: 1px solid $color-gray-10;
        border-radius: 0;
        box-shadow: none;
        line-height: 20px;
        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-width: 0 8px 11px;
            background: 0;
        }
        &:before {
            top: -11px;
            border-bottom-color: $color-gray-10;
        }
        &:after {
            top: -9px;
            border-bottom-color: $color-gray-13;
        }

        .c-button--normal {
            width: 104px;
            height: 32px;
            border-color: $color-gray-10;
            border-radius: 4px;
            font-size: 13px;
            line-height: 30px;
            color: $color-gray-02;
        }
    }
}
