.c-dialog {
    display: none;
    z-index: 105;
    position: fixed;
    overflow: hidden;
    top: 50%;
    left: 50%;
    width: 280px;
    border-radius: 8px;
    background: $color-gray-14;
    box-shadow: 14px 18px 50px rgba(0, 0, 0, 0.3), 0 5px 10px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    transform: translate(-50%, -50%);

    &__title {
        display: none;
        padding: 0 16px;
        border-bottom: 1px solid $color-gray-11;
        font-weight: bold;
        color: $color-gray-02;
        text-align: center;
        word-break: break-all;

        @include mq-768 {
            display: block;
            padding: 21px 78px 20px;
            font-size: 19px;
            line-height: 28px;
        }
    }

    &__content {
        margin: 24px 16px 25px;
        font-size: 15px;
        line-height: 20px;

        &-info {
            font-size: 15px;
            font-weight: bold;
            color: $color-gray-02;
            strong {
                display: block;
            }
            .title {
                display: block;
                margin-bottom: 4px;
                font-size: 18px;
                line-height: 21px;
            }

            & + .c-dialog__text {
                margin-top: 13px;
                font-size: 14px;
                color: $color-gray-04;
            }
        }

        &-point {
            color: $color-11st-red;
        }
    }

    &__text {
        &:only-child {
            margin: 0 -6px;
            color: $color-gray-03;
        }
    }

    &__buttons {
        display: flex;
        position: static;
        height: 48px;
        margin: 0;
        padding: 0;
        border-top: 1px solid $color-gray-12;

        .c-dialog__button-point,
        .c-dialog__button-normal {
            display: block;
            flex: 1;
            height: 48px;
            padding: 0;
            border: 0;
            border-right: 1px solid $color-gray-13;
            background: $color-gray-14;
            font-size: 16px;
            color: $color-gray-03;
            line-height: 48px;
            text-align: center;
            box-sizing: border-box;

            &:last-child {
                border-right: none;
            }
        }

        .c-dialog__button-point {
            color: $color-11st-red;
        }
    }

    &__close {
        @include size(77px 70px);
        display: none;
        position: absolute;
        overflow: hidden;
        top: 0;
        right: 0;
        margin: 0;
        border: 0;
        color: transparent;
        line-height: 200px;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 3px;
            height: 50px;
            margin: -25px 0 0 -2px;
            background: $color-gray-02;
            content: "";
        }

        &:before {
            transform: scale(0.5) rotate(45deg);
        }

        &:after {
            transform: scale(0.5) rotate(-45deg);
        }

        @include mq-768 {
            display: block;
        }
    }

    &--center {
        text-align: center;
    }

    &--info {
        overflow-x: hidden;
        overflow-y: auto;
        width: 315px;
        max-height: 100%;
        -webkit-overflow-scrolling: touch;

        @include mq-down-360 {
            right: 24px;
            left: 24px;
            width: auto;
            transform: translateY(-50%);
        }
    }

    &--large {
        overflow: auto;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0;
        -webkit-overflow-scrolling: touch;
    }
}

@include mq-768 {
    .c-dialog {
        width: 340px;

        &__content {
            font-size: 16px;
            line-height: 24px;

            &-info {
                line-height: 20px;
                .title {
                    font-size: 19px;
                    font-weight: 900;
                    line-height: 23px;
                }
            }

            &-point {
                color: $color-11st-red;
            }
        }
        &__text {
            margin-top: 16px;
        }

        &--info {
            top: 235px;
            width: 500px;
            max-width: 500px;
            transform: translate(-50%, 0);
        }

        &--large {
            width: auto;
        }
    }
}
