// only PC
[data-ui-type="Contents_Login_Nudge"] {
    padding: 23px 14px 7px;

    .c-info {
        &__title {
            font-weight: 700;
            font-size: 20px;
            line-height: 33px;
            color: $color-gray-02;
            text-align: center;
        }
        &__user-name {
            &:before {
                @include get-sprite-member("ic-favicon");
                display: inline-block;
                margin: -1px 8px 1px 0;
                vertical-align: middle;
                content: "";
            }

            @each $type in $account-types {
                @if ($type != "phone") and ($type != "facebook") {
                    &--#{$type} {
                        &:before {
                            @include get-sprite-position-member("sns_id_#{$type}", 24);
                        }
                    }
                }
            }
        }
        &__point {
            color: $color-11st-red;
        }
    }

    .c-buttons {
        margin-top: 46px;
        .c-button {
            position: relative;
            font-size: 15px;
        }
        .c-bubble {
            @include blue-bubble($position-x: left, $position-y: top);
            position: absolute;
            top: -18px;
            left: 10px;
            background-color: #fafdfe;
        }
    }

    .c-notice {
        margin-top: 30px;
        font-size: 13px;
        line-height: 20px;

        &__desc {
            color: $color-gray-03;
        }
        &__emphasis {
            font-weight: 700;
            color: $color-gray-03;
        }
        &__detail {
            display: inline-block;
            position: relative;
            margin-top: 12px;
            border-bottom: 1px solid $color-gray-03;
            color: $color-gray-02;
            vertical-align: top;
            text-decoration: none;
            &:after {
                @include get-sprite-member("ic-arrow-right", 5);
                position: absolute;
                top: 50%;
                right: -9px;
                transform: translateY(-50%);
                content: "";
            }
        }
    }

    // 닫기 버튼
    & + .c-dialog__close {
        top: -2px;
        right: -6px;
    }
}
