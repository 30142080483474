@mixin btn-close-x($size: 10px, $weight: 1px, $color: #333) {
    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        right: 50%;
        top: 50%;
        height: $size;
        width: $weight;
        margin: -$size/2 0 0 $weight;
        background-color: $color;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}
