$account-types: (
    // 로그인 제외
    "11st",
    // 본인인증 제외
    "phone",
    // 로그인(Mobile) 제외
    "facebook",
    // 공통
    "kakao",
    "tid",
    "naver",
    "payco",
    "apple"
);

body {
    height: 100%;
}
button {
    border: 0;
    background: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
select,
textarea,
p,
blockquote,
th,
td,
button {
    font-family: -apple-system, BlinkMacSystemFont, "Apple SD Gothic", "Malgun Gothic", arial, sans-serif;
    @include mq-768 {
        font-family: $font-desktop;
    }
}

.mo-only {
    @include mq-768 {
        display: none;
    }
}
