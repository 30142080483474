[data-ui-type="Cap_Login_Adult"] {
    margin-bottom: 30px;
    padding-top: 40px;
    @include mq-768 {
        margin-bottom: 24px;
        padding-top: 50px;
    }

    .c-headline {
        text-align: center;
        &__logo {
            &:before {
                content: "";
                @include get-sprite-member("ic-19");
                display: block;
                margin: 0 auto;
            }
        }

        &__desc {
            margin-top: 10px;
            font-size: 15px;
            line-height: 20px;
            color: $color-gray-03;

            @include mq-768 {
                margin: 12px 0 4px;
                color: $color-gray-02;
            }
        }

        &__button {
            margin-top: 6px;
            font-size: 14px;
            color: $color-gray-06;
            line-height: 16px;

            @include mq-768 {
                line-height: 18px;
            }

            &:after {
                content: "";
                @include ico-arrow-right(7.5px, $color-gray-08);
                display: inline-block;
                margin: -2px 2px 1px 0;
                vertical-align: middle;
            }
        }
    }

    .c-infotip__layer {
        margin-top: 37px;
        @include mq-768 {
            margin-top: 24px;
        }
    }

    .c-button-close {
        overflow: hidden;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 36px;
        height: 36px;
        font-size: 1px;
        color: transparent;
        @include btn-close-x(26px, 1.5px);
    }
}
