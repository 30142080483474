// only PC
[data-ui-type="Contents_Passkey_Info"] {
    padding: 16px 24px 15px;
    .c-guide {
        display: flex;
        &__item {
            position: relative;
            flex-shrink: 0;
            color: $color-gray-02;
            &:not(:first-child) {
                padding-left: 72px;
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 22px;
                    width: 19px;
                    height: 19px;
                    margin-top: 9px;
                    border: solid $color-gray-06;
                    border-width: 3px 3px 0 0;
                    border-radius: 0 1px 0 0;
                    transform: rotate(45deg);
                }
            }
        }
        &__step {
            font-size: 19px;
            font-weight: 900;
            line-height: 22px;
        }
        &__info {
            margin-top: 16px;

            & + .c-guide__info {
                margin-top: 20px;
            }
        }
        &__info-image {
            width: 382px;
            vertical-align: top;
        }
    }
}
