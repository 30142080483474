[data-ui-type="Contents_Result"] {
    max-width: 350px;
    margin: 0 auto;
    .c-headline {
        &__title {
            padding-top: 33px;
            font-weight: bold;
            font-size: 22px;
            color: $color-gray-03;
            line-height: 28px;
            @include mq-768 {
                padding-top: 40px;
                font-size: 20px;
            }

            &--logo {
                padding-top: 43px;
                font-size: 21px;
                line-height: 26px;
                color: $color-gray-02;
                &:before {
                    content: "";
                    display: block;
                    margin-bottom: 32px;
                    @include img-svg($logo-11st, 56px, 24px);
                }
                @include mq-768 {
                    &:before {
                        display: none;
                    }
                }
            }
        }
        &__desc {
            margin-top: 8px;
            font-size: 15px;
            line-height: 21px;
            color: $color-gray-04;
            @include mq-768 {
                font-size: 14px;
            }
        }
    }

    .c-box {
        overflow: visible;
        position: relative;
        margin-top: 32px;
        padding: 16px;
        border: 1px solid $color-gray-09;
        border-radius: 4px;

        &__time {
            position: absolute;
            top: 100%;
            right: 0;
            margin-top: 8px;
        }
    }

    .c-list {
        font-size: 13px;
        line-height: 18px;
        dt {
            float: left;
            width: 63px;
            &:not(:first-of-type) {
                margin-top: 8px;
            }
        }
        dd {
            overflow: hidden;
            color: $color-gray-03;
            &:not(:first-of-type) {
                margin-top: 8px;
            }
        }
    }
}
