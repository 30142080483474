// 위치 분기 개발 가이드 필요
// PC: sns 하단, MW: sns 상단
[data-ui-type="Contents_Login_Remember"] {
    &:last-child {
        padding-bottom: 14px;
    }

    .c-util {
        display: flex;
        position: relative;
        margin-top: 12px;
        @include mq-768 {
            margin-top: 20px;
        }

        &__item {
            font-size: 0;
            .c-checkbox {
                & + label {
                    display: inline-block;
                    vertical-align: top;
                    font-size: 13px;
                    color: $color-gray-01;
                    @include mq-768 {
                        color: $color-gray-02;
                    }
                }
            }
            .c-iconinfo--help {
                width: 20px;
                height: 22px;
                vertical-align: top;
            }
        }

        &__link {
            display: inline-block;
            position: relative;
            vertical-align: top;
            font-size: 14px;
            line-height: 22px;
            color: $color-gray-03;
            @include mq-768 {
                font-size: 13px;
                color: $color-gray-02;
            }

            &:not(:first-child) {
                margin-left: 24px;
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: -12px;
                    width: 1px;
                    height: 14px;
                    margin-top: -7px;
                    background: $color-gray-09;
                }
                @include mq-768 {
                    margin-left: 17px;
                    &:before {
                        left: -9px;
                        margin-top: -6px;
                    }
                }
            }
        }
    }

    .c-tooltip {
        left: -30px;
        margin-top: 12px;
        &:before {
            left: 35px;
        }
    }

    .c-passkey {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;
        margin-top: 24px;
        padding: 0 16px;
        border-radius: 4px;
        background: #f8f8f8;
        &__item {
            display: flex;
            align-items: center;
            flex-shrink: 0;
        }
        &__text {
            padding-bottom: 1px;
            font-size: 13px;
            color: $color-gray-03;
        }
        &__button {
            display: inline-flex;
            align-items: center;
            height: 30px;
            padding: 0 12px 1px;
            border-radius: 360px;
            background: $color-11st-red;
            font-size: 14px;
            color: $color-gray-14;
            vertical-align: top;
        }
    }
}
