[data-ui-type="FlatButton_Login"] {
    margin-top: 53px;
    text-align: center;
    @include mq-768 {
        margin-top: 35px;
    }

    .c-join {
        &__link {
            display: inline-block;
            padding-left: 3px;
            vertical-align: top;
            font-weight: 700;
            font-size: 16px;
            color: $color-gray-03;
            line-height: 20px;
            @include mq-768 {
                font-size: 14px;
            }

            &:after {
                @include ico-arrow-right(8px, currentColor);
                content: "";
                display: inline-block;
                margin: -2px 0 1px 1px;
                vertical-align: middle;
            }
        }
    }

    // only PC
    .c-nonmember {
        position: relative;
        margin-top: 18px;

        &__button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 162px;
            height: 48px;
            border: 1px solid $color-gray-10;
            border-radius: 4px;
            font-size: 14px;
            color: $color-gray-02;
            vertical-align: top;
        }
        &__desc {
            font-size: 14px;
            color: $color-gray-04;
            text-align: center;
            line-height: 20px;
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 16px;
        }
        &__item {
            margin: 2px;
        }
    }

    .c-headline {
        &__desc {
            margin: 39px 0 10px;
            color: $color-gray-07;
            font-size: 13px;
            line-height: 19px;
        }
    }
}
