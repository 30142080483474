.c-buttons {
    display: flex;

    .c-button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 48px;
        border-radius: 4px;
        font-size: 17px;
        @include mq-768 {
            padding-bottom: 1px;
            font-size: 16px;
            line-height: 18px;
            &--normal {
                color: $color-gray-02;
            }
        }

        &:not(:first-child) {
            margin-left: 8px;
            @include mq-768 {
                margin-left: 10px;
            }
        }
    }

    &--fixed {
        position: fixed;
        left: 16px;
        bottom: 16px;
        right: 16px;

        @include mq-768 {
            position: static;
            margin-top: 24px;
        }
    }
}

.c-iconinfo {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 24px;
    height: 24px;
    text-indent: -9999px;
    color: transparent;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }

    &--help {
        &:after {
            @include img-svg($icon-help, 14px, 14px);
        }
    }
}
