// Font
// 폰트 관련 설정 내용이 많아 기존 _variable.scss와는 구분되는게 보기 좋을 듯

// Font - Body Base size
$base-size: 16px;

// Font- Variables (font) - 웹폰트 관련 정리 반영 필요함 (12.21)
$font-family: -apple-system, BlinkMacSystemFont, "Apple SD Gothic", "Malgun Gothic", "돋움", "Dotum", arial, sans-serif;
$font-desktop: Lato, "Noto Sans KR", $font-family;
$font-11st: "11StreetGothic", $font-family;
$font-pc-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;

// Font - face
/* noto-sans-kr-300 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
    src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("//c.011st.com/css/fonts/noto-sans-kr-v13-latin_korean-300.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("//c.011st.com/css/fonts/noto-sans-kr-v13-latin_korean-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-kr-regular - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("//c.011st.com/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("//c.011st.com/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-500 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    src: local("Noto Sans KR Medium"), local("NotoSansKR-Medium"), url("//c.011st.com/css/fonts/noto-sans-kr-v12-latin_korean-500.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("//c.011st.com/css/fonts/noto-sans-kr-v12-latin_korean-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-700 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 700;
    src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"), url("//c.011st.com/css/fonts/noto-sans-kr-v12-latin_korean-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("//c.011st.com/css/fonts/noto-sans-kr-v12-latin_korean-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-regular - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"), url("//c.011st.com/css/fonts/lato-v16-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("//c.011st.com/css/fonts/lato-v16-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039; //숫자 : U+0030-0039,영문 : U+0041-005A(대문자), U+0061-007A(소문자)
}

/* lato-900 - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    src: local("Lato Black"), local("Lato-Black"), url("//c.011st.com/css/fonts/lato-v16-latin-900.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("//c.011st.com/css/fonts/lato-v16-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039; //숫자 : U+0030-0039,영문 : U+0041-005A(대문자), U+0061-007A(소문자)
}

@font-face {
    font-family: "11StreetGothic";
    font-style: normal;
    font-weight: 400;
    src: url("//c.011st.com/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff2"), url("//c.011st.com/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff");
}

@font-face {
    font-family: "11StreetGothic";
    src: url("//c.011st.com/css/fonts/11StreetGothic_Optimizing.woff") format("woff2"), url("//c.011st.com/css/fonts/11StreetGothic_Optimizing.woff") format("woff");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "11StreetGothicBold";
    font-weight: bold;
    src: url("//c.011st.com/css/fonts/11STREET_Gothic_bold.eot");
    src: url("//c.011st.com/css/fonts/11STREET_Gothic_bold.woff") format("woff2"), url("//c.011st.com/css/fonts/11STREET_Gothic_bold.woff") format("woff"), url("//c.011st.com/css/fonts/11STREET_Gothic_bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    src: url("//c.011st.com/css/fonts/RobotoCondensed-Bold.eot");
    src: url("//c.011st.com/css/fonts/RobotoCondensed-Bold.woff") format("woff2"), url("//c.011st.com/css/fonts/RobotoCondensed-Bold.woff") format("woff"), url("//c.011st.com/css/fonts/RobotoCondensed-Bold.ttf") format("truetype");
}

// Font - Function (px to rem)
@function rem($fsize) {
    @return $fsize / $base-size * 1rem;
}

// Font - Headline
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-weight: bold;
}
h1 {
    font-size: rem(29px);
}
h2 {
    font-size: rem(25px);
}
h3 {
    font-size: rem(23px);
}
h4 {
    font-size: rem(16px);
}
h5 {
    font-size: rem(14px);
}
h6 {
    font-size: rem(12px);
}
