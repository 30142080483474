// 2023.08 renewal 영역 공통
@mixin login-new($block: &) {
    @at-root .login-wrapper {
        @if ($block) {
            #{$block} {
                @content;
            }
        } @else {
            @content;
        }
    }
}

// 로그인 > 최근로그인 라벨
@mixin latest-login($isBubble: true) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 18px;
    @if ($isBubble == true) {
        box-sizing: content-box;
        padding-bottom: 4px;
        background: url(/assets/img/svg/member/bg-bubble.svg) no-repeat;
        background-size: auto 100%;
        filter: drop-shadow(0px 1px 1px rgba($color-gray-01, 0.1));
    } @else {
        border: 1px solid rgba($color-11st-red, 0.2);
        border-radius: 20px;
        background: rgba($color-11st-red, 0.02);
    }
    font-weight: 400;
    font-size: 11px;
    color: $color-11st-red;
    line-height: 20px;
    text-align: center;
    vertical-align: top;

    @include mq-768 {
        width: 63px;
        @if ($isBubble == true) {
            padding-bottom: 5px;
        } @else {
            padding-bottom: 1px;
        }
    }
}

// 말풍선(flexible)
@mixin blue-bubble($position-x: center, $position-y: bottom) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 9px;
    border: 1px solid rgba($color-11st-blue, 0.2);
    border-radius: 100px;
    background: rgba($color-11st-blue, 0.02);
    height: 25px;
    @if ($position-y == bottom) {
        margin-top: 5px;
    } @else if($position-y == top) {
        margin-bottom: 5px;
    }
    background-size: auto 100%;
    font-weight: 700;
    font-size: 11px;
    color: $color-11st-blue;
    vertical-align: top;
    &:after {
        content: "";
        position: absolute;
        @if ($position-y == top) {
            top: 100%;
            transform: rotate(180deg);
        } @else if ($position-y == bottom) {
            bottom: 100%;
        }
        @if ($position-x == left) {
            left: 13px;
        } @else if ($position-x == center) {
            left: 50%;
            margin-left: -6px;
        } @else {
            left: $position-x;
        }
        width: 12px;
        height: 6px;
        background: url(/assets/img/svg/member/bg_bubble_blue_tail.svg) no-repeat;
        background-size: 100% 100%;
    }

    @include mq-768 {
        height: 28px;
        padding: 0 11px;
        font-size: 12px;
        @if ($position-y == bottom) {
            margin-top: 6px;
        } @else if($position-y == top) {
            margin-bottom: 6px;
        }
        &:after {
            height: 7px;
        }
    }
}
