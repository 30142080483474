[data-ui-type="Contents_Auth_Phone"] {
    @include mq-768 {
        max-width: 350px;
        margin: 0 auto;
    }

    .c-box {
        &__item {
            border-color: $color-gray-10;
            &:only-child {
                background: $color-gray-13;
            }
        }
        &__time {
            margin-right: 15px;
            @include mq-768 {
                margin-right: 19px;
            }
        }
        .c-input {
            padding-left: 15px;
            @include mq-768 {
                padding-left: 19px;
            }
        }
    }

    .c-buttons {
        margin-top: 16px;
        @include mq-768 {
            margin-top: 14px;
        }
        .c-button {
            &--kakao {
                &:not(:disabled) {
                    background: #fbe100;
                    color: #381e20;
                }
                &:disabled {
                    &:before {
                        @include get-sprite-member("ic-kakao-white");
                    }
                }

                &:before {
                    content: "";
                    display: block;
                    @include get-sprite-member("ic-kakao");
                    margin-right: 7px;
                    @include mq-768 {
                        margin-top: 2px;
                    }
                }
                em {
                    font-weight: bold;
                }
            }
        }
    }

    @extend %util-login;
}
