@charset "UTF-8";

@import "variables";

html {
    line-height: 1.15;
    height: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    background: $color-gray-14;
    color: $color-grayscale-04;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    margin: 0;
    overflow-wrap: break-word;
    -webkit-text-size-adjust: none;
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    *:before,
    *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
select,
textarea,
p,
blockquote,
th,
td,
button {
    margin: 0;
    padding: 0;
    font-family: $font-family;
}

section,
nav,
article,
aside,
hgroup,
header,
footer {
    display: block;
}

form,
fieldset,
img {
    border: 0 none;
}

table {
    border-spacing: 0;
    border: 0;
}

em {
    font-style: normal;
}

ul,
ol,
li {
    list-style: none outside;
}

input,
select {
    vertical-align: middle;
}

a {
    text-decoration: none;
    color: inherit;
}

legend,
caption,
.hide {
    visibility: hidden;
    overflow: hidden;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
}

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="search"],
input[type="email"] {
    background: $color-gray-14;
    color: $color-grayscale-04;
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

button,
input[type="submit"],
input[type="button"] {
    cursor: pointer;
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
}

select {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
}

textarea {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

iframe {
    display: block;
}

.sr-only,
.skip {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    width: 1px;
    height: 1px;
    font-size: 6px;
    line-height: 1;
    white-space: nowrap;
}

.js-focus-visible {
    button,
    input[type="checkbox"],
    input[type="radio"],
    select,
    summary {
        &:focus:not(.focus-visible) {
            outline: 0;
        }
    }

    select {
        &:focus:not(.focus-visible) {
            &::-ms-value {
                background-color: transparent;
                color: inherit;
            }
        }
    }

    // 폼요소 + label 형태
    input[type="checkbox"],
    input[type="radio"] {
        &:focus:not(.focus-visible) {
            & + label::before {
                box-shadow: none !important;
            }
            & + label {
                box-shadow: none !important;
            }
        }
    }

    [role="listbox"] {
        &:focus:not(.focus-visible) {
            outline: 0;
        }
    }
}
