// 구버전과 현버전(.login-wrapper) 혼용
[data-ui-type="Contents_Login_Sns"] {
    // 구버전 스타일 (auth 페이지)
    margin-top: 56px;
    text-align: center;

    @include mq-768 {
        margin-top: 43px;
    }

    .c-headline {
        &__desc {
            font-size: 13px;
            color: $color-gray-06;
            @include mq-768 {
                color: $color-gray-07;
            }
        }
    }

    .c-sns {
        overflow: hidden;
        position: relative;
        width: 264px;
        height: 36px;
        margin: 10px auto 0;
        @include mq-768 {
            width: 290px;
            height: 40px;
            margin-top: 13px;
        }

        &--show {
            height: 96px;
            @include mq-768 {
                height: 112px;
            }
            .c-sns__button-toggle {
                &:before {
                    margin: 4px auto 0;
                    transform: rotate(315deg);
                }
            }
        }
        &--col3 {
            width: 210px;
        }
        &--col5 {
            @include mq-768 {
                width: 300px;
                padding-right: 7px;
                box-sizing: content-box;
                .c-sns__item {
                    margin: 0 13px 28px 0;
                    &:last-child {
                        margin-right: 0;
                        margin-left: 7px;
                    }
                }
            }
        }
        &__list {
            font-size: 0;
        }
        &__item {
            display: inline-block;
            margin: 0 10px 24px;
            vertical-align: top;
            @include mq-768 {
                margin-bottom: 32px;
            }
        }
        &__link {
            display: block;
            @include get-sprite-member;
            &--kakao {
                @include get-sprite-position-member("sns-kakao", 36);
            }
            &--tid {
                @include get-sprite-position-member("sns-tid", 36);
            }
            &--naver {
                @include get-sprite-position-member("sns-naver", 36);
            }
            &--mobile {
                @include get-sprite-position-member("sns-mobile", 36);
            }
            &--payco {
                @include get-sprite-position-member("sns-payco", 36);
            }
            &--apple {
                @include get-sprite-position-member("sns-apple", 36);
            }
            &--facebook {
                @include get-sprite-position-member("sns-facebook-new", 36);
            }

            @include mq-768 {
                &--kakao {
                    @include get-sprite-position-member("sns-kakao", 40);
                }
                &--tid {
                    @include get-sprite-position-member("sns-tid", 40);
                }
                &--naver {
                    @include get-sprite-position-member("sns-naver", 40);
                }
                &--mobile {
                    @include get-sprite-position-member("sns-mobile", 40);
                }
                &--payco {
                    @include get-sprite-position-member("sns-payco", 40);
                }
                &--apple {
                    @include get-sprite-position-member("sns-apple", 40);
                }
                &--facebook-n {
                    @include get-sprite-position-member("sns-facebook-new", 40);
                }
                &--facebook {
                    @include get-sprite-position-member("sns-facebook-new", 40);
                    // @include get-sprite-position-member("sns-facebook", 40);
                }
            }
            > span {
                @include sr-only;
            }
        }
        &__button-toggle {
            position: absolute;
            top: 6px;
            right: 0;
            width: 24px;
            height: 24px;
            @include mq-768 {
                top: 8px;
            }
            &:before {
                @include ico-arrow-down(8px, $color-gray-03);
                content: "";
                display: block;
                margin: 0 auto 4px;
            }
        }
    }

    // 로그인 renewal default, 신규 스타일은 하위에 선언
    @include login-new {
        margin-top: 14px;
        .c-sns {
            overflow: visible;
            height: auto;
            margin-top: 0;
            @include mq-768 {
                width: auto;
            }

            &__list {
                margin-left: -24px;
                @include mq-768 {
                    display: flex;
                    justify-content: space-between;
                    margin-left: -15px;
                }
            }

            &__item {
                position: relative;
                margin: 16px 0 0 24px;
                @include mq-768 {
                    margin-left: 15px;
                }
            }

            &__link {
                width: 40px;
                height: 40px;
                background: none;
                @include mq-768 {
                    width: 44px;
                    height: 44px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    font-size: 10px;
                }
            }

            &--ios {
                .c-sns {
                    &__list {
                        margin-left: -20px;
                    }
                    &__item {
                        margin-left: 20px;
                    }
                    &__link {
                        @each $type in $account-types {
                            @if $type != "11st" and $type != "facebook" {
                                &--#{$type} {
                                    width: 44px;
                                    height: 44px;
                                    img {
                                        content: url(/assets/img/svg/member/sns-#{$type}-ios.svg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .c-bubble {
            @include latest-login;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, 7px);
        }
    }
}
