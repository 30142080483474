[data-ui-type="Contents_App_Permission"] {
    max-width: 350px;
    margin: 0 auto;
    padding: 32px 0;
    .c-headline {
        &__title {
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
            color: $color-gray-03;
        }
        &__desc {
            margin-top: 8px;
            font-size: 15px;
            line-height: 21px;
            color: $color-gray-04;

            @include mq-768 {
                font-size: 14px;
            }
        }
    }

    .c-box {
        overflow: visible;
        position: relative;
        margin-top: 32px;
        padding: 16px;
        border: 1px solid $color-gray-09;
        border-radius: 4px;

        &__time {
            position: absolute;
            top: 100%;
            right: 0;
            margin-top: 8px;
        }
    }

    .c-list {
        font-size: 13px;
        line-height: 18px;
        dt {
            float: left;
            width: 63px;
            &:not(:first-of-type) {
                margin-top: 8px;
            }
        }
        dd {
            overflow: hidden;
            color: $color-gray-03;
            &:not(:first-of-type) {
                margin-top: 8px;
            }
        }
    }
    .c-buttons {
        margin-top: 39px;
    }
}
