// Only display content to screen readers. A la Bootstrap 4.
//
// See: a11yproject.com/posts/how-to-hide-content/
@mixin sr-only($image-replacement: false) {
    overflow: hidden;
    @if ($image-replacement == false) {
        position: absolute;
        clip: rect(0 0 0 0);
        margin: -1px;
        width: 1px;
        height: 1px;
    }
    font-size: 6px;
    line-height: 1;
    color: transparent;
    white-space: nowrap;
    user-select: none;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links;
// see : www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate
@mixin sr-only-focusable {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        clip: auto;
    }
}
