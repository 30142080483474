.c-input {
    display: block;
    width: 100%;
    padding: 0 16px;
    border: 0;
    color: $color-gray-02;
    caret-color: $color-11st-red;

    &::-ms-clear,
    &::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }

    &::placeholder {
        color: $color-gray-07;
        @include mq-768 {
            color: $color-gray-06;
        }
    }

    &[readonly] {
        background-color: $color-gray-13;
        pointer-events: none;
    }

    // 자동완성 배경색,폰트 대응
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-text-fill-color: $color-gray-02;
        box-shadow: 0 0 0px 1000px $color-gray-14 inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    .c-box & {
        flex: 1;
        min-width: 0;
        height: 100%;
    }
}

.c-radio {
    &--check {
        & + label {
            padding-left: 27px;
            @include mq-768 {
                padding-left: 32px;
            }
            &:before {
                @include get-sprite-member("ic-check-off");
                position: absolute;
                top: 50%;
                left: 3px;
                transform: translateY(-50%);
                border: 0;
                @include mq-768 {
                    @include get-sprite-position-member("ic-check-off", 18);
                    left: 0;
                }
            }
            &:after {
                display: none;
            }
        }
        &:checked + label {
            &:before {
                @include get-sprite-member("ic-check-on");
                @include mq-768 {
                    @include get-sprite-position-member("ic-check-on", 18);
                }
            }
            &:after {
                display: none;
            }
        }
    }
}
