// only MW
[data-ui-type="Cap_Login_Mobile"] {
    margin: 30px 0;

    .c-headline {
        text-align: center;
        &__desc {
            font-size: 13px;
            color: $color-gray-06;
            line-height: 19px;
        }
    }

    .c-buttons {
        position: relative;
        margin-top: 16px;

        .c-button--app {
            height: 52px;
            border-radius: 4px;
            background: linear-gradient(97deg, $color-11st-gradient-orange 0%, $color-11st-red 49.47%, $color-11st-gradient-pink 100%);
            font-size: 17px;
            font-weight: 700;
            color: $color-gray-14;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
        }
    }

    .c-bubble {
        @include latest-login;
        position: absolute;
        top: -14px;
        left: 50%;
        transform: translateX(-50%);
    }
}
