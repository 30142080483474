.c-infotip {
    position: relative;

    &__layer {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-width: 400px;
        z-index: 20;
        background: $color-gray-14;
        border-radius: 4px;
        padding: 16px;
        box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.36);
        color: $color-gray-03;
        text-align: left;

        @include mq-768 {
            padding: 17px 20px 20px;
            color: $color-gray-02;
        }

        &--show {
            display: block;
        }
    }

    dt {
        font-weight: bold;
        font-size: 15px;
        line-height: 19px;
        &:first-child {
            padding-right: 26px;
        }
        @include mq-768 {
            font-size: 16px;
            line-height: 23px;
        }
    }
    dd,
    &__desc {
        margin-top: 12px;
        font-size: 14px;
        line-height: 20px;

        &:first-child {
            margin-top: 0;
            padding-right: 26px;
        }

        @include mq-768 {
            font-size: 15px;
            line-height: 22px;
        }
    }

    &__button-close {
        @include btn-close-x(16px);
        position: absolute;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;

        @include mq-768 {
            top: 16px;
            right: 16px;
        }
    }

    &__point {
        color: $color-11st-red;
    }
}
