[data-ui-type="Contents_Login_Util"] {
    text-align: center;
    margin-bottom: 30px;
    @include mq-768 {
        min-height: 38px;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .c-util {
        display: inline-flex;
        vertical-align: top;
        &__item {
            display: flex;
            align-items: center;
            &:not(:first-child) {
                margin-left: 6px;
                &:before {
                    content: "";
                    display: block;
                    width: 3px;
                    height: 3px;
                    margin-right: 6px;
                    border-radius: 50%;
                    background-color: $color-gray-10;
                }
                @include mq-768 {
                    margin-left: 10px;
                    &:before {
                        margin-right: 10px;
                    }
                }
            }
            .c-iconinfo {
                width: 18px;
                height: 18px;
                margin-right: -2px;
            }
            .c-infotip__layer {
                margin-top: 9px;
                padding: 14px 16px;
            }
        }

        &__link {
            display: block;
            font-size: 14px;
            color: $color-gray-01;
            line-height: 17px;
        }
    }
    // only PC
    .c-nonmember {
        position: relative;

        &__title {
            font-size: 16px;
            color: $color-gray-02;
            line-height: 23px;
        }

        &__desc {
            margin-top: 13px;
            font-size: 15px;
            color: $color-gray-06;
            line-height: 22px;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: 6px 0 0 -10px;
            white-space: normal;
        }

        &__item {
            width: 50%;
            padding: 10px 0 0 10px;
        }

        &__link {
            display: block;
            width: 100%;
            padding: 15px;
            border-radius: 4px;
            border: 1px solid $color-gray-09;
            font-size: 16px;
            color: $color-gray-02;
            line-height: 1;
            text-align: center;
        }

        .c-tooltip-wrap {
            position: static;
            text-align: left;
        }
        .c-tooltip {
            left: 0;
            width: 386px;
            margin-top: 10px;
            padding: 16px 20px 20px;
            box-shadow: 0px 6px 20px -8px rgba($color-gray-01, 0.05), 0px 0px 1px 0px rgba($color-gray-01, 0.36);
            &:before {
                display: none;
            }

            &__button-close {
                top: 22px;
                right: 22px;
                width: 12px;
                height: 12px;
                &:before,
                &:after {
                    background: $color-gray-02;
                }
            }
        }
    }

    // only PC Popup
    .c-bridge {
        margin-top: 20px;
        text-align: center;
        &__button {
            display: inline-block;
            min-width: 162px;
            height: 48px;
            border: 1px solid $color-gray-10;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 700;
            color: $color-gray-02;
            line-height: 46px;
            vertical-align: top;
        }
    }
}
