.c-button {
    display: inline-block;
    padding: 0 8px;
    border-radius: 0;
    text-align: center;

    &--primary {
        border: 1px solid $color-11st-red;
        background: $color-11st-red;
        color: $color-gray-14;
    }

    &--alert {
        border: 1px solid $color-11st-red;
        background: $color-gray-14;
        color: $color-11st-red;
    }

    &--normal {
        border: 1px solid $color-gray-09;
        background: $color-gray-14;
        color: $color-gray-03;
    }

    &:disabled,
    &--disabled {
        border: 1px solid $color-gray-09;
        background: $color-gray-09;
        color: $color-gray-14;
    }

    &--dimmed {
        border: 1px solid rgba(204, 204, 204, 0.2);
        background: $color-grayscale-12;
        color: $color-gray-09;
    }

    &__point {
        color: $color-11st-red;
    }
}
