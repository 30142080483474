// 구버전과 현버전(.login-wrapper) 혼용
[data-ui-type="Cap_Login_Logo"] {
    // 구버전 스타일 (auth 페이지)
    .c-headline {
        padding-top: 40px;
        text-align: center;

        &__logo {
            display: inline-flex;
            align-items: center;
            position: relative;
            height: 88px;
            vertical-align: top;
            &:before {
                content: "";
                display: block;
                @include img-svg($logo-11st, 88px, 38px);
            }
            a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &__desc {
            font-size: 15px;
            line-height: 21px;
            color: $color-gray-04;
        }

        @include mq-768 {
            &__logo {
                height: 123px;
                &:before {
                    @include img-svg($logo-11st, 100px, 43px);
                }
            }

            &__desc {
                font-size: 18px;
                color: $color-gray-03;
                letter-spacing: -1px;
                line-height: 24px;
            }
        }
    }

    .c-button-close {
        overflow: hidden;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 36px;
        height: 36px;
        font-size: 1px;
        color: transparent;
        @include btn-close-x(26px, 1.5px);
    }

    // 로그인 renewal default, 신규 스타일은 하위에 선언
    @include login-new {
        .c-headline {
            margin-bottom: 30px;
            @include mq-768 {
                padding-top: 50px;
                margin-bottom: 40px;
            }

            &__logo {
                height: auto;
                &:before {
                    width: 60px;
                    height: 26px;
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                    @include mq-768 {
                        width: 75px;
                        height: 32px;
                    }
                }
            }
        }
        .c-button-close {
            &:before,
            &:after {
                height: 24px;
                margin-top: -12px;
            }
        }
    }
}
