[data-grid="contents-wrapper"] {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
    @include mq-768 {
        display: block;
    }
}

[data-grid="contents-title"] {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 31;
    width: 100%;
    height: 56px;
    border-bottom: 1px solid $color-gray-10;
    background: $color-gray-14;

    @include mq-768 {
        position: relative;
        max-width: 960px;
        height: 106px;
        margin: 0 auto;
        padding-top: 20px;
        border-color: $color-gray-03;
    }

    .c-headline {
        &__title {
            display: flex;
            align-items: center;
            padding: 0 16px;
            font-size: 19px;
            color: $color-gray-01;

            @include mq-768 {
                font-size: 26px;
                color: $color-gray-02;
                line-height: 38px;
            }
        }
        &__logo {
            display: block;
            overflow: hidden;
            font-size: 1px;
            color: transparent;
            text-indent: -999px;
            @include mq-768 {
                @include img-svg($logo-11st, 64px, 28px);
                margin-right: 15px;
            }
        }
        &__button-close {
            overflow: hidden;
            position: absolute;
            top: 50%;
            right: 10px;
            width: 36px;
            height: 36px;
            margin-top: -18px;
            font-size: 1px;
            color: transparent;
            @include btn-close-x(26px, 1.5px);

            @include mq-768 {
                right: 0;
                margin-top: -6px;
            }
        }
    }
}

[data-grid="contents-area"] {
    flex: 1;
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
    padding: 0 16px 20px;

    @include mq-768 {
        max-width: 472px;
    }

    [data-grid="contents-title"] + & {
        padding-top: 56px;

        @include mq-768 {
            padding-top: 0;
        }
    }

    @include login-new {
        flex: none;
        max-width: 500px;
        padding: 0 20px 30px;
        box-sizing: border-box;

        @include mq-768 {
            margin-top: 100px;
            padding: 0 50px 30px;
            border: 1px solid $color-gray-11;
            border-radius: 10px;
            box-shadow: 0px 2px 6px 0px rgba($color-gray-01, 0.06);
        }
    }
}

[data-grid="contents-bottom"] {
    flex-shrink: 0;
    max-width: 375px;
    margin: 0 auto;
    padding: 0 16px 40px;

    @include mq-768 {
        max-width: 472px;
    }

    @include login-new {
        max-width: none;
    }
}

.contents-quick-login {
    @include mq-768 {
        display: flex;
        min-height: 588px;
        [data-grid="contents-area"] {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 588px;
            padding-bottom: 40px;
        }
    }
}
@include login-new(false) {
    &.contents-quick-login {
        [data-grid="contents-area"] {
            display: block;
            min-height: auto;
        }
    }
}
