[data-ui-type="Contents_Auth_App"] {
    @include mq-768 {
        max-width: 350px;
        margin: 0 auto;
    }

    .c-box {
        &__item {
            padding: 0 15px;
            border-color: $color-gray-10;
            @include mq-768 {
                padding: 0 19px;
            }
            &:only-child {
                background: $color-gray-13;
            }
        }
        &__device {
            flex: 1;
            position: relative;
            min-width: 0;
        }
        &__date {
            margin-left: 12px;
            font-size: 13px;
            color: $color-gray-04;
            text-align: right;
            white-space: nowrap;
        }
        .c-radio {
            & + label {
                @include mq-768 {
                    margin-left: -7px;
                }
            }
            &--check {
                & + label {
                    @include mq-768 {
                        margin-left: 0;
                    }
                }
            }
        }
        .label {
            display: block;
            overflow: hidden;
            font-size: 15px;
            color: $color-gray-02;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .c-buttons {
        margin-top: 14px;
    }

    @extend %util-login;
}
