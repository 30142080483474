$sprite-meta-member: (
    "name": 'member', // sprite 이름
    "width": 234, // sprite 판 너비
    "height": 198, // sprite 판 높이
    "imageSrc": '/assets/img/svg/sprites/sp_member_202418_150536.svg'
);

$sprite-map-member: (
  'ic-19': (
      "width": 40,
      "height": 40,
      "bg-top": 0 - (48 - 40)/2,
      "bg-left": 0 - (48 - 40)/2,
  ),
  'ic-arrow': (
      "width": 13,
      "height": 8,
      "bg-top": -66 - (16 - 8)/2,
      "bg-left": -212 - (21 - 13)/2,
  ),
  'ic-arrow-right': (
      "width": 6,
      "height": 11,
      "bg-top": -82 - (19 - 11)/2,
      "bg-left": -212 - (14 - 6)/2,
  ),
  'ic-arrow-right-gray': (
      "width": 16,
      "height": 28,
      "bg-top": -44 - (36 - 28)/2,
      "bg-left": -140 - (24 - 16)/2,
  ),
  'ic-cancel': (
      "width": 18,
      "height": 18,
      "bg-top": -84 - (26 - 18)/2,
      "bg-left": -184 - (26 - 18)/2,
  ),
  'ic-check': (
      "width": 14,
      "height": 14,
      "bg-top": -176 - (22 - 14)/2,
      "bg-left": 0 - (22 - 14)/2,
  ),
  'ic-check-blue': (
      "width": 14,
      "height": 9,
      "bg-top": -176 - (17 - 9)/2,
      "bg-left": -22 - (22 - 14)/2,
  ),
  'ic-check-off': (
      "width": 14,
      "height": 14,
      "bg-top": 0 - (22 - 14)/2,
      "bg-left": -212 - (22 - 14)/2,
  ),
  'ic-check-on': (
      "width": 14,
      "height": 14,
      "bg-top": -22 - (22 - 14)/2,
      "bg-left": -212 - (22 - 14)/2,
  ),
  'ic-favicon': (
      "width": 24,
      "height": 24,
      "bg-top": -140 - (32 - 24)/2,
      "bg-left": -36 - (32 - 24)/2,
  ),
  'ic-info': (
      "width": 14,
      "height": 14,
      "bg-top": -44 - (22 - 14)/2,
      "bg-left": -212 - (22 - 14)/2,
  ),
  'ic-input-hide': (
      "width": 28,
      "height": 28,
      "bg-top": -80 - (36 - 28)/2,
      "bg-left": -140 - (36 - 28)/2,
  ),
  'ic-input-show': (
      "width": 28,
      "height": 28,
      "bg-top": -140 - (36 - 28)/2,
      "bg-left": 0 - (36 - 28)/2,
  ),
  'ic-kakao': (
      "width": 18,
      "height": 18,
      "bg-top": -110 - (26 - 18)/2,
      "bg-left": -184 - (26 - 18)/2,
  ),
  'ic-kakao-white': (
      "width": 18,
      "height": 18,
      "bg-top": -136 - (26 - 18)/2,
      "bg-left": -184 - (26 - 18)/2,
  ),
  'ic-time': (
      "width": 16,
      "height": 16,
      "bg-top": -116 - (24 - 16)/2,
      "bg-left": -140 - (24 - 16)/2,
  ),
  'sns-apple': (
      "width": 36,
      "height": 36,
      "bg-top": 0 - (44 - 36)/2,
      "bg-left": -96 - (44 - 36)/2,
  ),
  'sns-facebook': (
      "width": 40,
      "height": 40,
      "bg-top": 0 - (48 - 40)/2,
      "bg-left": -48 - (48 - 40)/2,
  ),
  'sns-facebook-new': (
      "width": 40,
      "height": 40,
      "bg-top": -48 - (48 - 40)/2,
      "bg-left": 0 - (48 - 40)/2,
  ),
  'sns-kakao': (
      "width": 36,
      "height": 36,
      "bg-top": -44 - (44 - 36)/2,
      "bg-left": -96 - (44 - 36)/2,
  ),
  'sns-mobile': (
      "width": 40,
      "height": 40,
      "bg-top": -48 - (48 - 40)/2,
      "bg-left": -48 - (48 - 40)/2,
  ),
  'sns-naver': (
      "width": 36,
      "height": 36,
      "bg-top": -96 - (44 - 36)/2,
      "bg-left": 0 - (44 - 36)/2,
  ),
  'sns-payco': (
      "width": 36,
      "height": 36,
      "bg-top": -96 - (44 - 36)/2,
      "bg-left": -44 - (44 - 36)/2,
  ),
  'sns-phone': (
      "width": 36,
      "height": 36,
      "bg-top": -96 - (44 - 36)/2,
      "bg-left": -88 - (44 - 36)/2,
  ),
  'sns-tid': (
      "width": 36,
      "height": 36,
      "bg-top": 0 - (44 - 36)/2,
      "bg-left": -140 - (44 - 36)/2,
  ),
  'sns_id_11st': (
      "width": 20,
      "height": 20,
      "bg-top": -140 - (28 - 20)/2,
      "bg-left": -68 - (28 - 20)/2,
  ),
  'sns_id_apple': (
      "width": 20,
      "height": 20,
      "bg-top": -140 - (28 - 20)/2,
      "bg-left": -96 - (28 - 20)/2,
  ),
  'sns_id_facebook': (
      "width": 20,
      "height": 20,
      "bg-top": -140 - (28 - 20)/2,
      "bg-left": -124 - (28 - 20)/2,
  ),
  'sns_id_kakao': (
      "width": 20,
      "height": 20,
      "bg-top": -140 - (28 - 20)/2,
      "bg-left": -152 - (28 - 20)/2,
  ),
  'sns_id_naver': (
      "width": 20,
      "height": 20,
      "bg-top": 0 - (28 - 20)/2,
      "bg-left": -184 - (28 - 20)/2,
  ),
  'sns_id_payco': (
      "width": 20,
      "height": 20,
      "bg-top": -28 - (28 - 20)/2,
      "bg-left": -184 - (28 - 20)/2,
  ),
  'sns_id_tid': (
      "width": 20,
      "height": 20,
      "bg-top": -56 - (28 - 20)/2,
      "bg-left": -184 - (28 - 20)/2,
  ),
);

@mixin get-sprite-position-member($name, $width: null) {
    $sp-w: map-get($sprite-meta-member, "width");
    $sp-h: map-get($sprite-meta-member, "height");
    $img: map-get($sprite-map-member, $name);
    $img-w: map-get($img, "width");
    $img-h: map-get($img, "height");
    $img-r: $img-w / $img-h;
    $img-bgt: map-get($img, "bg-top");
    $img-bgl: map-get($img, "bg-left");

    @if $width == null {
      $width: $img-w;
    }

    $height: $width / $img-r;

    $bgw: floor($width * ($sp-w / $img-w));
    $bgh: floor($height * ($sp-h / $img-h));
    $bgl: ($bgw / $sp-w) * $img-bgl;
    $bgt: ($bgh / $sp-h) * $img-bgt;

    width: #{$width}px;
    height: #{$height}px;
    background-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
    background-size: #{$bgw}px #{$bgh}px;
}

@mixin get-sprite-member($name: null, $tmp-width: null) {
    $sp-path: map-get($sprite-meta-member, "imageSrc");

    background: url(#{$sp-path}) no-repeat;
    @if $name != null {
      @include get-sprite-position-member($name, $width: $tmp-width);
    }
}
