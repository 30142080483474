.c-spinner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    color: transparent;
    text-indent: -9999px;

    &:before {
        @include size(60px);
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 200;
        margin: -30px 0 0 -30px;
        border-radius: 30px;
        background: #fff url("/assets/img/common/loading2.gif") no-repeat 50% 50%;
        background-size: 30px 30px;
        color: transparent;
        content: "";
    }

    // MPINNER-11457 배포 후 삭제예정
    & > span {
        @include sr-only;
    }
    // //MPINNER-11457 배포 후 삭제예정

    &-inner {
        padding: 50px 0;
        text-align: center;

        &:before {
            @include size(60px);
            display: inline-block;
            border-radius: 30px;
            background: #fff url("/assets/img/common/loading.gif") no-repeat 50% 50%;
            background-size: 40px 40px;
            color: transparent;
            content: "";
        }

        // MPINNER-11457 배포 후 삭제예정
        & > span {
            @include sr-only;
        }
        // //MPINNER-11457 배포 후 삭제예정
    }
}
