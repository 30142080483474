[data-ui-type="Contents_Oauth_Notice"] {
    @include mq-768 {
        max-width: 328px;
        margin: 0 auto;
    }

    .c-notice {
        @include mq-768 {
            margin-top: 80px;
            font-size: 14px;
        }

        &__title {
            margin-bottom: 8px;
            @include mq-768 {
                margin-bottom: 14px;
                line-height: 20px;
            }
        }
        &__desc {
            position: relative;
            color: $color-gray-06;
            line-height: 16px;
            word-break: break-all;
            @include mq-768 {
                line-height: 20px;
                letter-spacing: -0.01em;
            }

            &:not(:last-child) {
                @include mq-768 {
                    margin-bottom: 10px;
                }
            }

            &:before {
                content: "";
                position: absolute;
                top: 6px;
                right: calc(100% + 4px);
                width: 2px;
                height: 2px;
                background-color: $color-gray-04;

                @include mq-768 {
                    top: 10px;
                }
            }

            em {
                color: $color-11st-red;
            }
        }
    }
}
