// only PC
[data-ui-type="Contents_Login_Footer"] {
    padding: 0 0 85px;

    .copyright {
        font-size: 12px;
        color: $color-gray-06;
        line-height: 18px;
        text-align: center;
    }
}
