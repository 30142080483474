[data-ui-type="Contents_Register_Info"] {
    position: relative;
    margin: 47px 0 49px;
    @include mq-768 {
        margin: 27px 14px 56px;
    }
    .c-info {
        text-align: center;
        &__desc {
            margin-top: 8px;
            font-size: 14px;
            line-height: 20px;
        }
        &__desc-primary {
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
            color: $color-gray-03;
            br {
                @include mq-768 {
                    display: none;
                }
            }
            .point {
                color: $color-11st-red;
            }
        }
    }

    .c-info-img {
        margin-top: 4px;
        padding: 0 12px;
        @include mq-768 {
            margin-top: 2px;
        }

        &__content {
            position: relative;
            max-width: 272px;
            margin: 0 auto;
            padding-top: 214 / 272 * 100%;
            background: url("/assets/img/member/img_register_info.png") no-repeat center top;
            background-size: 100%;
            @include mq-768 {
                max-width: 300px;
                padding-top: 250px;
            }
        }
        &__text-id {
            overflow: hidden;
            position: absolute;
            top: 52.8%;
            left: 54px;
            right: 54px;
            font-weight: bold;
            font-size: 20px;
            color: $color-gray-01;
            line-height: 28px;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include mq-768 {
                top: 126px;
                font-size: 22px;
                font-weight: 900;
            }
        }
    }

    .c-notice {
        margin: 8px 3px 0 0;
        @include mq-768 {
            margin-top: 26px;
        }

        &__title {
            margin-bottom: 4px;
            color: $color-gray-02;
            line-height: 20px;
        }
        &__desc {
            margin-bottom: 0;
            color: $color-gray-04;
            br {
                display: none;
                @include mq-768 {
                    display: inline;
                }
            }
        }
    }

    .c-buttons {
        margin-top: 8px;
        @include mq-768 {
            margin-top: 26px;
        }
    }

    .c-close-today {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 12px;
        font-size: 14px;
        color: $color-gray-03;
        line-height: 17px;
        white-space: nowrap;
        @include mq-768 {
            margin-top: 18px;
        }
        &:before {
            content: "";
            display: inline-block;
            @include get-sprite-member("ic-check");
            margin: -1px 4px 1px 0;
            vertical-align: middle;
        }
    }
}
