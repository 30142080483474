.c-box {
    &__item {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        height: 57px;
        border: 1px solid $color-gray-09;

        &:only-child {
            height: 58px;
            border-radius: 4px;
        }
        &:not(:only-child) {
            &:first-child {
                border-radius: 4px 4px 0 0;
            }
            &:last-child {
                border-radius: 0 0 4px 4px;
            }
            &:not(:first-child) {
                border-top: 0;
            }
        }
    }

    &__text,
    .c-input {
        flex: 1;
        background: transparent;
        font-size: 15px;
        color: $color-gray-02;
    }

    &__time {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 13px;
        color: $color-11st-blue;
        white-space: nowrap;
        dt {
            @include get-sprite-member("ic-time");
            flex-shrink: 0;
            overflow: hidden;
            margin-right: 4px;
            font-size: 6px;
            color: transparent;
            user-select: none;
        }
        dd {
            width: 33px; // 타이머 숫자 변경될 때 유동적으로 영역 변경되는 이슈 대응
            height: 15px;
        }
    }

    &__button {
        &-delete {
            flex-shrink: 0;
            position: relative;
            height: 100%;
            padding: 0 16px;

            .icon {
                display: block;
                overflow: hidden;
                position: relative;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background: $color-gray-08;
                font-size: 1px;
                color: transparent;
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 1px;
                    height: 10px;
                    background: $color-gray-14;
                }
                &:before {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
                &:after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }
    }
}

.c-box-wrap {
    > dt {
        display: block;
        margin-bottom: 4px;
        font-size: 13px;
        line-height: 22px;
    }
}
