[data-ui-type="Contents_Quick_Login"] {
    $block: &;

    padding-top: 30px;
    @include mq-768 {
        padding-bottom: 13px;
    }

    &:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px solid $color-gray-11;
    }

    .c-headline {
        position: relative;
        margin-bottom: 16px;
        text-align: center;
        @include mq-768 {
            margin-bottom: 24px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            height: 1px;
            background: $color-gray-11;
        }

        &__desc {
            display: inline-block;
            position: relative;
            padding: 0 5px;
            background: $color-gray-14;
            font-size: 13px;
            color: $color-gray-06;
            line-height: 17px;
            vertical-align: top;
            @include mq-768 {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }

    .c-box {
        border: 1px solid $color-gray-11;
        border-radius: 4px;
        box-shadow: 0px 2px 6px 0px rgba($color-gray-01, 0.06);

        &__item {
            height: 52px;
            border: 0;
            &:not(:only-child):not(:first-child) {
                border-top: 1px solid $color-gray-11;
            }
            &--active {
                .c-box__button-id {
                    &:before {
                        @include get-sprite-position-member("ic-check-on");
                    }
                }
            }
        }

        &__button-id {
            display: flex;
            align-items: center;
            flex: 1;
            position: relative;
            height: 100%;
            font-weight: 600;
            font-size: 16px;
            color: $color-gray-02;
            text-align: left;

            &:before {
                @include get-sprite-member("ic-check-off");
                content: "";
                flex-shrink: 0;
                margin: 0 10px 0 16px;
            }

            @include mq-768 {
                padding-bottom: 2px;
                font-size: 15px;
                &:before {
                    margin-bottom: -2px;
                }
            }
        }

        &__button-delete {
            padding: 0 19px;
        }
    }

    .c-bubble {
        @include latest-login(false);
        flex-shrink: 0;
        position: relative;
        transform: translateZ(0);
        margin-left: 6px;
        @include mq-768 {
            margin-bottom: -2px;
        }
    }

    // only App
    .c-bridge {
        margin-top: 30px;
        text-align: center;

        &__link {
            display: inline-block;
            padding-left: 3px;
            vertical-align: top;
            font-weight: 700;
            font-size: 14px;
            color: $color-gray-03;
            line-height: 17px;
            &:after {
                @include ico-arrow-right(8px, currentColor);
                content: "";
                display: inline-block;
                margin: -2px 0 1px 1px;
                vertical-align: middle;
            }
        }
    }

    @include login-new(false) {
        [data-ui-type="Cap_Login_Logo"] {
            & + #{$block} {
                padding-top: 0;

                .c-headline {
                    &:before {
                        display: none;
                    }

                    &__desc {
                        background: transparent;
                    }
                }
            }
        }

        [data-ui-type="Cap_Login_Mobile"] {
            & + #{$block} {
                padding-top: 0;

                .c-headline {
                    @include mq-768 {
                        &:before {
                            display: none;
                        }

                        &__desc {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
}
