// only PC
[data-ui-type="Banner_Login"] {
    overflow: hidden;
    max-width: 500px;
    margin: 0 4px 20px;
    border-radius: 4px;
    a {
        display: block;
    }
    img {
        width: 100%;
        min-height: 60px;
        vertical-align: top;
        object-fit: cover;
    }
    @include mq-768 {
        margin-left: auto;
        margin-right: auto;
    }
}
