.c-radio,
.c-checkbox {
    @include size(22px);
    position: absolute;
    opacity: 0;

    & + label {
        display: block;
        position: relative;
        min-height: 22px;
        padding-left: 30px;
        font-size: 15px;
        line-height: 22px;
        cursor: pointer;

        &:before {
            @include size(22px);
            position: absolute;
            top: 0;
            left: 0;
            border: 1px solid $color-gray-08;
            border-radius: 50%;
            background-color: $color-gray-14;
            box-sizing: border-box;
            content: "";
        }

        &:after {
            position: absolute;
            content: "";
        }
    }

    &[disabled] {
        cursor: not-allowed;

        & + label {
            cursor: not-allowed;
            color: $color-gray-09;

            &:before {
                border-color: $color-gray-11;
                background: $color-gray-11;
            }

            &:hover,
            &:before,
            &:after {
                cursor: not-allowed;
            }
        }
    }

    &:focus {
        & + label {
            &:before {
                box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
            }
        }
    }

    &:checked {
        & + label {
            &:before {
                border-color: $color-11st-red;
                background: $color-11st-red;
                animation-name: none;
            }

            &:after {
                display: block;
            }
        }

        &[disabled] {
            & + label {
                &:before {
                    border-color: $color-gray-11;
                    background: $color-gray-11;
                }
            }
        }
    }
}

.c-checkbox {
    & + label {
        color: $color-gray-03;

        &:after {
            @include size(6px 10px);
            top: 4px;
            left: 8px;
            border: 1px solid $color-gray-10;
            border-width: 0 1px 1px 0;
            box-sizing: border-box;
            transform: rotate(45deg);
        }
    }

    &:checked {
        & + label {
            &:after {
                border-color: $color-gray-14;
            }
        }
    }

    &[disabled] {
        & + label {
            &:after {
                border-color: $color-gray-14;
            }
        }
    }

    &--shape {
        + label {
            &:before {
                border-radius: 0;
            }
        }
    }
}

.c-radio {
    & + label {
        &:before {
            border-radius: 50%;
        }

        &:after {
            @include size(10px);
            background: $color-gray-10;
            top: 6px;
            left: 6px;
            border-radius: 50%;
        }
    }

    &[disabled] {
        & + label {
            &:after {
                background: $color-gray-14;
            }
        }
    }

    &:checked + label {
        &:after {
            background-color: $color-gray-14;
        }
    }
}
