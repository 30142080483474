[data-ui-type="Contents_Auth_Pin"] {
    @include mq-768 {
        max-width: 350px;
        margin: 0 auto;
    }

    .c-box {
        &__item {
            border-color: $color-gray-10;
        }
        &__time {
            flex-shrink: 0;
        }
        .c-input {
            padding-left: 15px;
            @include mq-768 {
                padding-left: 19px;
            }
        }
        .c-button {
            flex-shrink: 0;
            width: 60px;
            height: 32px;
            margin: 0 12px 0 8px;
            border-color: $color-gray-08;
            border-radius: 360px;
            font-size: 14px;

            &[disabled] {
                border-color: rgba($color-gray-09, 0.2);
                background-color: $color-gray-13;
                color: $color-gray-09;
            }
        }
    }

    .c-buttons {
        margin-top: 16px;
        @include mq-768 {
            margin-top: 14px;
        }
    }

    @extend %util-login;
}
