// Path
$svgPath: "/assets/img/svg";

// Logo
$logo-amazon: url("#{$svgPath}/logo/amazon.svg");
$logo-amazon-white: url("#{$svgPath}/logo/amazon_white.svg");
$logo-amazon-white2: url("#{$svgPath}/logo/amazon_white2.svg");
$logo-amazon-black: url("#{$svgPath}/logo/amazon_black.svg");
$logo-amazon-icon: url("#{$svgPath}/logo/amazon_icon.svg");
$logo-olkl: url("#{$svgPath}/logo/logo_olkl.svg"); //우주
$logo-sktpass: url("#{$svgPath}/logo/logo_olklpass.svg"); //우주패스
$logo-sktpass2: url("#{$svgPath}/logo/logo_olklpass2.svg"); //우주패스 - 배경없는 버전
$logo-sktmembership: url("#{$svgPath}/logo/logo_tmembership.svg"); //t멤버십
$logo-t: url("#{$svgPath}/logo/logo_t.svg"); //t멤버십 정사각형
$logo-tpluspoint: url("#{$svgPath}/logo/logo_tpluspoint.svg");
$logo-icon_star: url("#{$svgPath}/logo/star.svg");
$logo-icon_star_fill: url("#{$svgPath}/logo/star_fill.svg");
$logo-icon_soldout: url("#{$svgPath}/logo/icon_soldout.svg");
$logo-shocking-delivery: url("#{$svgPath}/logo/shocking_delivery.svg");
$logo-11st: url("#{$svgPath}/logo/11st.svg");

// Icon
$icon-search-white: url("#{$svgPath}/ic-search-white.svg");
$icon-search-black: url("#{$svgPath}/ic-search-black.svg");
$icon-help: url("#{$svgPath}/ic-help.svg");

@mixin img-svg($logo-var, $width, $height) {
    width: $width;
    height: $height;
    background-image: $logo-var;
    background-size: $width $height;
}
